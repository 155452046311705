import React, { Component, useState } from "react"
import "./Borrowers.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'
import { Row, Col, Form, Button as But } from 'react-bootstrap'
import { Button, Input, Space, Table, Skeleton, Switch, Tag, Modal, Select, Spin, Dropdown, Tooltip, message } from 'antd'
import { SearchOutlined, CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import * as com from '../Common'
import { UR_LoanOfficer, UR_Owner, UR_Underwriter, UR_Closer } from "../State"
import { capitalize } from "lodash"
import { checkAccess } from "../Auth"
import { CaretDownOutlined } from '@ant-design/icons'
import { withRouter } from "react-router-dom"
import BulkChangeModal from "./BulkChangeModal"
import LoanBorrowerPopUp from "../Common/components/loanOrBorrow"
const currentLoanStatuses = [
    'interviewstarted', 'interviewfinished', 'documentationrequested',
    'loansubmitted', 'conditionallyapproved', 'applicationwithdrawn', 'applicationdenied',
    "pendingrateselection", "readyforsubmit", "indocs", "fundsreleased"
]
const manualupdateStatuses = [
    "loansubmitted", "conditionallyapproved", "applicationwithdrawn", "applicationdenied",
    "indocs", "fundsreleased"
]
const { confirm } = Modal
const updateLoanProcessor = (loanid, slpid, role, onHide) => {
    let token = sessionStorage.getItem("ZeitroA")
    fetch('/los/updateloanteam', {
        body: JSON.stringify({ LoanID: loanid, LOID: slpid, Role: role }),
        method: 'POST',
        headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
        }
    }).then(
        response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status)
                onHide()
                return
            } else {
                onHide()
            }
        }
    ).catch(function (err) {
        console.log('Fetch Error :-S', err)
    })
}

function AssignLoanProcessor(props) {

    const [slpid, setSlpid] = useState("")
    const handleSubmit = event => {
        updateLoanProcessor(props.loan_id, slpid, "LoanProcessor", props.onHide)
    }
    const showOptions = () => {
        const selected = e => {
            setSlpid(e.target.value)
        }

        return (
            <Row className="mt-1">
                <Col className="px-0">
                    <Form.Group controlId="propertytype" className="text-left" >
                        <Form.Control name="propertytype" as="select"
                            defaultValue={props.lp_id}
                            type="text"
                            required
                            onChange={selected}
                            style={{ fontSize: 14, borderRadius: 6, border: "1px solid #ECECEC" }}
                        >
                            <option value="" >Select loan processor</option>

                            {props.loanprocessors.map(t => {
                                return (
                                    <option value={t.id}>{t.firstname} {t.lastname}</option>
                                )
                            }
                            )
                            }
                        </Form.Control>
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            Please select loan processor.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        )

    }
    const showNotes = () => {
        if (slpid === "") return <></>
        const newLpName = props.loanprocessors.find(t => t.id === slpid).firstname + " " + props.loanprocessors.find(t => t.id === slpid).lastname
        if (props.lpName === newLpName) return <></>
        return (
            <Row style={{ marginTop: -10 }}>
                <Col className="px-0">
                    <div style={titleStyle}>*{newLpName} will replace {props.lpName} as the borrower's loan processor</div>
                </Col>
            </Row>
        )
    }
    const titleStyle = { fontSize: 12, color: "#6E6E70", fontWeight: 500 }
    return (
        <Modal
            {...props}
            title="Assign loan processor to the borrower"
            open={props.show}
            onCancel={props.handleClose}
            onOk={handleSubmit}
            okText="Submit"
            okButtonProps={{
                disabled: slpid === "",
            }}
        >
            <div className="pt-3 pb-3">

                <Row style={{ borderRadius: 6, backgroundColor: "#F5F5F7", padding: "15px 23px" }}>
                    <Col>
                        <div style={titleStyle}>Borrower</div>
                        <div>{props.name}</div>
                    </Col>
                    <Col>
                        <div style={titleStyle}>Assigned Loan Processor</div>
                        <div>{props.lpName}</div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col className="px-0">
                        <div style={titleStyle}>Loan Processor</div>
                        <div>
                            {showOptions()}
                        </div>
                    </Col>
                </Row>
                {showNotes()}


            </div>
        </Modal>
    )
}

function AssignLoanOfficer(props) {

    const updateLoanOfficer = (id, sid) => {
        let token = sessionStorage.getItem("ZeitroA")
        let body = { loanid: id, losid: sid }
        fetch('/los/assignlostoborrower', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            },
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status)
                    props.onHide()
                    return
                }
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert("Error: " + js.Text)
                        props.onHide()

                    } else {
                        props.onHide()

                    }
                })
            }
        ).catch(function (err) {
            alert('Fetch Error :-S', err)
        })
    }
    const [sid, setSid] = useState("")
    const handleSubmit = event => {
        updateLoanOfficer(props.loan_id, sid)
    }
    const showOptions = () => {
        const selected = e => {
            setSid(e.target.value)
        }

        return (
            <Row className="mt-1">
                <Col className="px-0">
                    <Form.Group controlId="propertytype" className="text-left" >
                        <Form.Control name="propertytype" as="select"
                            defaultValue={props.los_id}
                            type="text"
                            required
                            onChange={selected}
                            style={{ fontSize: 14, borderRadius: 6, border: "1px solid #ECECEC" }}
                        >
                            <option value="" >Select loan officer</option>

                            {props.loanofficers.map(t => {
                                return (
                                    <option value={t.id}>{t.firstname} {t.lastname}</option>
                                )
                            }
                            )
                            }
                        </Form.Control>
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Please select loan officer.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        )

    }
    const showNotes = () => {
        if (sid === "") return <></>
        const newLOName = props.loanofficers.find(t => t.id === sid).firstname + " " + props.loanofficers.find(t => t.id === sid).lastname
        if (props.loName === newLOName) return <></>
        return (
            <Row style={{ marginTop: -10 }}>
                <Col className="px-0">
                    <div style={titleStyle}>*{newLOName} will replace {props.loName} as the borrower's loan officer</div>
                </Col>
            </Row>
        )
    }
    const titleStyle = { fontSize: 12, color: "#6E6E70", fontWeight: 500 }
    return (
        <Modal
            {...props}
            title="Assign loan officer to the borrower"
            open={props.show}
            onCancel={props.handleClose}
            onOk={handleSubmit}
            okText="Submit"
            okButtonProps={{
                disabled: sid === "",
            }}
        >
            <div className="pt-3 pb-3">

                <Row style={{ borderRadius: 6, backgroundColor: "#F5F5F7", padding: "15px 23px" }}>
                    <Col>
                        <div style={titleStyle}>Borrower</div>
                        <div>{props.name}</div>
                    </Col>
                    <Col>
                        <div style={titleStyle}>Assigned Loan Officer</div>
                        <div>{props.loName}</div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col className="px-0">
                        <div style={titleStyle}>Loan Officer</div>
                        <div>
                            {showOptions()}
                        </div>
                    </Col>
                </Row>
                {showNotes()}


            </div>
        </Modal>
    )
}

function AssignUnderwriter(props) {

    const [underwriterId, setUnderwriterId] = useState('')
    const handleSubmit = event => {
        updateLoanProcessor(props.loan_id, underwriterId, "Underwriter", props.onHide)
    }
    const showOptions = () => {
        const selected = e => {
            setUnderwriterId(e.target.value)
        }

        return (
            <Row className="mt-1">
                <Col className="px-0">
                    <Form.Group controlId="propertytype" className="text-left" >
                        <Form.Control name="propertytype" as="select"
                            defaultValue={props.uw_id}
                            type="text"
                            required
                            onChange={selected}
                            style={{ fontSize: 14, borderRadius: 6, border: "1px solid #ECECEC" }}
                        >
                            <option value="" >Select underwriter</option>

                            {props.underwriters.map(t => {
                                return (
                                    <option value={t.ID}>{t.FirstName} {t.LastName}</option>
                                )
                            }
                            )
                            }
                        </Form.Control>
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Please select underwriter.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        )

    }
    const showNotes = () => {
        if (underwriterId === "") return <></>
        const newUWName = props.underwriters.find(t => t.id === underwriterId).firstname + " " + props.underwriters.find(t => t.id === underwriterId).lastname
        if (props.uwName === newUWName) return <></>
        return (
            <Row style={{ marginTop: -10 }}>
                <Col className="px-0">
                    <div style={titleStyle}>*{newUWName} will replace {props.uwName} as the borrower's underwriter</div>
                </Col>
            </Row>
        )
    }
    const titleStyle = { fontSize: 12, color: "#6E6E70", fontWeight: 500 }
    return (
        <Modal
            {...props}
            title="Assign underwriter to the borrower"
            open={props.show}
            onCancel={props.handleClose}
            onOk={handleSubmit}
            okText="Submit"
            okButtonProps={{
                disabled: underwriterId === "",
            }}
        >
            <div className="pt-3 pb-3">

                <Row style={{ borderRadius: 6, backgroundColor: "#F5F5F7", padding: "15px 23px" }}>
                    <Col>
                        <div style={titleStyle}>Borrower</div>
                        <div>{props.name}</div>
                    </Col>
                    <Col>
                        <div style={titleStyle}>Assigned Underwriter</div>
                        <div>{props.uwName}</div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col className="px-0">
                        <div style={titleStyle}>Underwriter</div>
                        <div>
                            {showOptions()}
                        </div>
                    </Col>
                </Row>
                {showNotes()}


            </div>
        </Modal>
    )
}

function AssignCloser(props) {
    const [closerId, setCloserId] = useState('')
    const handleSubmit = event => {
        updateLoanProcessor(props.loan_id, closerId, "Closer", props.onHide)
    }
    const showOptions = () => {
        const selected = e => {
            setCloserId(e.target.value)
        }

        return (
            <Row className="mt-1">
                <Col className="px-0">
                    <Form.Group controlId="propertytype" className="text-left" >
                        <Form.Control name="propertytype" as="select"
                            defaultValue={props.closer_id}
                            type="text"
                            required
                            onChange={selected}
                            style={{ fontSize: 14, borderRadius: 6, border: "1px solid #ECECEC" }}
                        >
                            <option value="" >Select Closer</option>

                            {props.closer.map(t => {
                                return (
                                    <option value={t.ID}>{t.FirstName} {t.LastName}</option>
                                )
                            }
                            )
                            }
                        </Form.Control>
                        <Form.Control.Feedback >Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" >
                            Please select underwriter.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        )

    }
    const showNotes = () => {
        if (closerId === "") return <></>
        const newCloserName = props.closer.find(t => t.id === closerId).firstname + " " + props.closer.find(t => t.id === closerId).lastname
        if (props.closerName === newCloserName) return <></>
        return (
            <Row style={{ marginTop: -10 }}>
                <Col className="px-0">
                    <div style={titleStyle}>*{newCloserName} will replace {props.closerName} as the borrower's closer</div>
                </Col>
            </Row>
        )
    }
    const titleStyle = { fontSize: 12, color: "#6E6E70", fontWeight: 500 }
    return (
        <Modal
            {...props}
            title="Assign closer to the borrower"
            open={props.show}
            onCancel={props.handleClose}
            onOk={handleSubmit}
            okText="Submit"
            okButtonProps={{
                disabled: closerId === "",
            }}
        >
            <div className="pt-3 pb-3">

                <Row style={{ borderRadius: 6, backgroundColor: "#F5F5F7", padding: "15px 23px" }}>
                    <Col>
                        <div style={titleStyle}>Borrower</div>
                        <div>{props.name}</div>
                    </Col>
                    <Col>
                        <div style={titleStyle}>Assigned Closer</div>
                        <div>{props.closerName}</div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col className="px-0">
                        <div style={titleStyle}>Closer</div>
                        <div>
                            {showOptions()}
                        </div>
                    </Col>
                </Row>
                {/* {showNotes()} */}


            </div>
        </Modal>
    )
}

class Borrower extends Component {

    constructor(props) {
        super(props)
        this.state = {
            openLoanPopup: false,
            borrowers: [],
            allborrowers: [],
            loanofficers: {},
            loanprocessors: [],
            allLoanProcessors: [],
            allUnderwriters: [],
            allCloser: [],
            name: "", // borrower name for assigning loan officer
            loName: "", // loan officer name for assigning loan officer
            lpName: "", // loan processor name for assigning loan processor
            uwName: "",
            closerName: "",
            id: "",
            loanofficers_full: [],
            loading: true,
            show: false,
            bulkType: '',
            showassignlo: false,
            showassignlp: false,
            showassignuw: false,
            showassignCloser: false,
            searchText: '',
            searchedColumn: '',
            searchedText: '',
            tableFilters: {
                loanOfficer: undefined,
                loanProcessor: undefined,
                loanPurpose: undefined,
                status: undefined,
            },
            loading: false,
            showErrorModal: false,
            editingCell: { loanID: null, columnKey: null },
            loadingStatus: false,
            firstName: '',
            lastName: '',
            isArchivedLoans: false,
            filterByLoanOfficer: false,
            selectedRowKeys: [],
            pagination: {
                current: 1,
                pageSize: 10
            },
        }
        this.searchInput = React.createRef()
        this.ismobile = window.innerWidth < 576
        this.viewportWidth = window.innerWidth
        this.ishardmoneyloancustomer = com.isHardMoneyLoanCustomer()
    }

    toggleEdit = (loanID, columnKey) => {
        this.setState(prevState => ({
            editingCell: {
                loanID: prevState.editingCell.loanID === loanID && prevState.editingCell.columnKey === columnKey ? null : loanID,
                columnKey: prevState.editingCell.columnKey === columnKey && prevState.editingCell.loanID === loanID ? null : columnKey
            }
        }))
    }
    changeSeleteLos = () => {

    }
    handleOperate = (key) => {
        let ids = this.state.selectedRowKeys
        switch (key) {
            case 'status':
                this.bulkHandle('status')
                break
            case 'loanofficer':
                this.bulkHandle('loanofficer')
                break
            case 'addArchived':
                this.changeArchived(ids, 'add')
                break
            case 'delete':
                this.changeArchived(ids, 'remove')
                break
            default:
                break
        }
    }
    handleArchived = (ids, type) => {
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        let body = { LoanIDs: ids }
        let api = type === 'add' ? '/los/bulkachiveloans' : '/los/bulkunachiveloans'
        fetch(api, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(response => {
            this.setState({ loading: false })
            if (response.status !== 200) {
                return
            }
            this.reload()
            if (type === 'add') {
                message.success('The selected loans have been archived.')
            } else {
                message.success('The selected loans have been unarchived.')
            }
            this.setState({ selectedRowKeys: [] })
        }).catch(function (err) {
            this.setState({ loading: false })
        })
    }
    bulkHandle = (type) => {
        this.setState({ bulkType: type, show: true })
    }
    handleRemoveArchived = () => {

    }
    changeArchived = (ids, type) => {
        if (type === 'add') {
            this.handleArchived(ids, 'add')
        } else {
            confirm({
                title: 'Do you want to remove the selected loans from the archived loans?',
                icon: <ExclamationCircleFilled />,
                onOk: () => {
                    this.handleArchived(ids, 'remove')
                },
                onCancel() {

                },
            })
        }
    }
    handleFilterChange = (filterType, value) => {
        this.setState(prevState => ({
            tableFilters: {
                ...prevState.tableFilters,
                [filterType]: value
            }
        }))
        this.resetPagination()
    }

    handleTableChange = (pagination) => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                current: pagination.current,
                pageSize: pagination.pageSize
            }
        })
    }

    getFilteredData = () => {
        return this.reverseAndsort(this.state.borrowers.map(this.borrowersJSON)).filter(s => {
            return (
                (this.state.tableFilters.loanOfficer ? s.loanofficer === this.state.tableFilters.loanOfficer : true) &&
                (this.state.tableFilters.loanProcessor ? s.loanprocessor === this.state.tableFilters.loanProcessor : true) &&
                (this.state.tableFilters.loanPurpose ? s.purpose === this.state.tableFilters.loanPurpose : true) &&
                (this.state.tableFilters.status ? s.status === this.state.tableFilters.status : true) &&
                (this.state.tableFilters.underwriter ? s.underwriter === this.state.tableFilters.underwriter : true) &&
                (this.state.tableFilters.closer ? s.closer === this.state.tableFilters.closer : true)
            )
        })
    }

    handleStatusChange = (loanID, newStatus) => {
        this.setState({ loadingStatus: true })
        let token = sessionStorage.getItem("ZeitroA")
        let body = { LoanID: loanID, Key: "status", Value: newStatus, IsAdmin: checkAccess([UR_Owner]) ? true : false }
        fetch('/los/updatepipeline', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(response => {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status)
                this.setState({ showErrorModal: true, loadingStatus: false })
                return
            }
            this.toggleEdit(null, null)
            const updatedBorrowers = this.state.borrowers.map(borrower => {
                if (borrower.loan_id === loanID) {
                    return { ...borrower, status: newStatus }
                }
                return borrower
            })
            this.setState({ borrowers: updatedBorrowers, loadingStatus: false })
        })
            .catch(function (err) {
                console.log('Fetch Error :-S', err)
            })
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        })
    };

    handleReset = (clearFilters) => {
        clearFilters()
        this.setState({ searchText: '' })
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={this.searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && this.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            })
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.current?.select(), 100)
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    componentDidMount() {


        this.reload()
        this.getProfile()
    }
    getAllLoanProcessor = async () => {
        try {
            let token = sessionStorage.getItem("ZeitroA")
            const response = await fetch('/los/getloanprocessorslist', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            })
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status)
                this.setState({ loading: false })
                return
            }
            const js = await response.json()
            let lp = js.AllLP.map(s => {
                return s.FirstName + " " + s.LastName
            })
            this.setState({ loanprocessors: lp, allLoanProcessors: js.AllLP })
        } catch (error) {
            this.setState({ loading: false })
            console.log('Fetch Error :-S', error)
        }
    }

    getAssignedLoanProcessor = async () => {
        try {
            let token = sessionStorage.getItem("ZeitroA")
            const response = await fetch('/los/getassignedloanprocessor', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            })
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status)
                this.setState({ loading: false })
                return
            }
            const js = await response.json()
            let br = this.state.allborrowers.map(s => {
                let loanprocessor = "Unassigned"
                let underwriter = "Unassigned"
                let closer = "Unassigned"
                let lpsid = ""
                let underwriterid = ""
                let closerid = ""
                for (let lp in js.AssignedLPs) {
                    if (s.loan_id == js.AssignedLPs[lp].LoanID) {
                        this.state.allLoanProcessors.map(l => {
                            if (l.ID == js.AssignedLPs[lp].ID) {
                                loanprocessor = l.FirstName + " " + l.LastName
                                lpsid = l.ID
                            }
                        })
                    }
                }
                for (let u of js.AssignedUnderwriters) {
                    if (u.LoanID === s.loan_id) {
                        this.state.allUnderwriters.map(l => {
                            if (l.ID == u.ID) {
                                underwriter = l.FirstName + " " + l.LastName
                                underwriterid = l.ID
                            }
                        })
                    }
                }
                for (let clo of js.AssignedClosers) {
                    if (clo.LoanID === s.loan_id) {
                        this.state.allCloser.map(l => {
                            if (l.ID == clo.ID) {
                                closer = l.FirstName + " " + l.LastName
                                closerid = l.ID
                            }
                        })
                    }
                }
                return {
                    ...s,
                    loanprocessor: loanprocessor,
                    underwriter: underwriter,
                    closer: closer,
                    lpsid: lpsid,
                    underwriterid: underwriterid,
                    closerid: closerid
                }
            })
           
            
            this.setState({ borrowers: br, allborrowers: br, loading: false })
        } catch (error) {
            this.setState({ loading: false })
            console.log('Fetch Error :-S', error)
        }
    }
    getProfile = () => {
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/getprofile', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(
            response => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Please contact Zeitro for help.')
                    return
                }
                // Examine the text in the response
                response.json().then(js => {
                    if (js.Status !== "OK") {
                        alert("Error: " + js.Error)
                    } else {
                        let lo = js.LoanOfficers[0]
                        this.setState({
                            firstName: lo.FirstName, lastName: lo.LastName,
                        })
                    }
                })
            }
        ).catch(function (err) {
        })
    }

    getLoanofficers = async () => {
        try {
            let token = sessionStorage.getItem("ZeitroA")
            const response = await fetch('/los/loanofficerlist', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            })
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status)
                alert('Looks like there was a problem. Please try later.')
                this.setState({ loading: false })
                return
            }
            const js = await response.json()
            if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
            } else {
                let docs = js.LoanOfficers.filter(el => el.Roles.includes(UR_LoanOfficer))
                docs = docs.map(s => {
                    let lo = this.state.loanofficers
                    lo[s.ID] = s.FirstName + " " + s.LastName
                    this.setState({ loanofficers: lo })
                    return {
                        rand: Math.random(), id: s.ID, email: s.Email,
                        firstname: s.FirstName, lastname: s.LastName, phone: s.Phone, image: s.Image === "" ? "/avatar.png" : s.Image
                    }
                })
                let underwriters = js.LoanOfficers.filter(el => el.Roles.includes(UR_Underwriter))
                let closers = js.LoanOfficers.filter(el => el.Roles.includes(UR_Closer))
                this.setState({ loanofficers_full: docs, allCloser: closers, allUnderwriters: underwriters })
            }
            let br = this.state.borrowers.map(s => {
                let lo = "Unassigned"
                if (this.state.loanofficers[s.los_id])
                    lo = this.state.loanofficers[s.los_id]

                return { ...s, loanofficer: lo, rand: Math.random() }
            })
            this.setState({ borrowers: br, allborrowers: br })
        } catch (error) {
            console.log('Fetch Error :-S', error)
            this.setState({ loading: false })
        }
    }
    reload = async () => {
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        try {
            const response = await fetch('/los/loansformanagement', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            })
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' +
                    response.status)
                this.setState({ loading: false })
                return
            }
            const js = await response.json()
            if (js.Status !== "OK") {
                console.log("Error: " + js.Text)
            } else {
                if (js.Borrowers) {
                    let docs = js.Borrowers.map(s => {
                        return {
                            rand: Math.random(), id: s.ID, loan_id: s.LoanID, firstname: s.FirstName,
                            lastname: s.LastName,
                            email: s.Email, status: s.Status, started: s.Started, phone: s.ContactPhone,
                            los_id: s.LoanOfficerID,
                            purpose: s.LoanPurpose,
                            hascontract: s.HasContract,
                            unknownpurpose: s.UnknownPurpose,
                            address: s.PropertyAddressStreet,
                            county: s.PropertyAddressCounty,
                            state: s.PropertyAddressState,
                            loanstatus: s.LoanStatus,
                            typeofmortgage: s.TypeOfMortgage
                        }
                    })
                    if (this.ishardmoneyloancustomer) {
                        docs = docs.filter(s => s.typeofmortgage === "hardmoneyloan")
                    }
                    this.setState({ borrowers: docs, allborrowers: docs })
                }
                await Promise.all([
                    this.getLoanofficers(),
                    this.getAllLoanProcessor()
                ])
                await this.getAssignedLoanProcessor()
                this.applyFilters()
            }
        } catch (error) {
            this.setState({ loading: false })
            console.log('Fetch Error :-S', error)
        }
    }
    showAssign = (id, loan_id, name, loanofficer) => {
        this.setState({ id: id, loan_id: loan_id, name: name, loName: loanofficer })
        this.setState({ showassign: true })
    }
    showAssignLO = (id, loan_id, name, loanofficer) => {
        this.setState({ id: id, loan_id: loan_id, name: name, loName: loanofficer })
        this.setState({ showassignlo: true })
    }
    showAssignLP = (id, loan_id, name, loanprocessor) => {
        this.setState({ id: id, loan_id: loan_id, name: name, lpName: loanprocessor })
        this.setState({ showassignlp: true })
    }
    showAssignUW = (id, loan_id, name, underwriter) => {
        this.setState({ id: id, loan_id: loan_id, name: name, uwName: underwriter })
        this.setState({ showassignuw: true })
    }
    showAssignCloser = (id, loan_id, name, closer) => {
        this.setState({ id: id, loan_id: loan_id, name: name, uwName: closer })
        this.setState({ showassignCloser: true })
    }
    hideAssign = () => {
        this.setState({ showassign: false })
        this.reload()
    }
    hideAssignlo = () => {
        this.setState({ showassignlo: false })
        this.reload()
    }
    hideAssignlp = () => {
        this.setState({ showassignlp: false })
        this.reload()
    }
    hideAssignUw = () => {
        this.setState({ showassignuw: false })
        this.reload()
    }
    hideAssignCloser = () => {
        this.setState({ showassignCloser: false })
        this.reload()
    }
    getPurpose = (purpose, hascontract, unknownpurpose) => {
        if (purpose === "refinance") return "Refinance"
        if (purpose === "cashoutrefinance") return "Cash-out Refinance"
        if (purpose === "purchaserehab") return 'Purchase and Rehab'
        if (purpose === "purchase") {
            if (unknownpurpose) return "TBD"
            if (!hascontract) return "Pre-qualification"
            return "Purchase"
        }
        return "TBD"
    }

    applyFilters = () => {
        const { isArchivedLoans, filterByLoanOfficer, firstName, lastName, allborrowers } = this.state
        let filteredBorrowers = allborrowers
        if (isArchivedLoans) {
            filteredBorrowers = filteredBorrowers.filter(s => s.loanstatus === "archived")
        } else {
            filteredBorrowers = filteredBorrowers.filter(s => s.loanstatus !== "archived")
        }
        if (filterByLoanOfficer) {
            filteredBorrowers = filteredBorrowers.filter(s => s.loanofficer === `${firstName} ${lastName}`)
        }
        this.setState({ borrowers: filteredBorrowers })
    }

    handleArchivedToggle = (e) => {
        const isArchived = e.key === 'archived'
        this.setState({ isArchivedLoans: isArchived, selectedRowKeys: [] }, this.applyFilters)
    }
    handleLoansToggle = (e) => {
        this.setState({ filterByLoanOfficer: e, selectedRowKeys: [] }, this.applyFilters)
    }
    borrowersJSON = (document, index) => {
        // console.log(document, 888)

        let dt = new Date(com.timeGoToJS(document.started))
        return {
            address: document.address,
            countystate: document.county + ", " + com.convertStateToAbbr(document.state),
            name: document.firstname + " " + document.lastname,
            email: document.email,
            loanofficer: document.loanofficer,
            phone: document.phone,
            status: document.status,
            started: dt.toLocaleDateString(),
            id: document.id,
            loanid: document.loan_id,
            loanprocessor: document.loanprocessor,
            purpose: this.getPurpose(document.purpose, document.hascontract, document.unknownpurpose),
            underwriter: document.underwriter,
            closer: document.closer,
            loanstatus: document.loanstatus,
            typeofmortgage: document.typeofmortgage,

        }
    }
    reverseAndsort = (array) => {
        let a = array.sort((a, b) => {
            if (com.dateFromDate(a.started) < com.dateFromDate(b.started)) {
                return -1
            }
            if (com.dateFromDate(a.started) > com.dateFromDate(b.started)) {
                return 1
            }
            return 0
        })
        let temp = []
        for (let i = a.length - 1; i >= 0; i--) {
            temp.push(a[i])
        }
        return temp
    }
    processAddress = (address) => {
        const regex = /^(.*?),\s*([A-Za-z\s]+,\s*[A-Z]{2}\s*\d{5})$/
        const match = address.match(regex)

        if (match && match.length === 3) {
            return <>
                <div>{match[1].trim()}</div>
                <div style={{ color: "#6E6E70", fontSize: 12 }}>{match[2].trim()}</div>
            </>
        } else {
            return address
        }

    }
    onRowClick = (record) => {
        if (record.loanofficer === "Unassigned" || record.loanofficer === this.state.firstName + " " + this.state.lastName) {
            return {
                onClick: () => {
                    console.log(record)

                    this.switchLoan(record.loanid)
                }
            }
        } else {
            return {}
        }
    }

    resetPagination = () => {
        this.setState({
            pagination: { ...this.state.pagination, current: 1 }
        })
    }

    switchLoan = (loanid) => {
        sessionStorage.setItem("edit", "false")
        sessionStorage.setItem("state", "{}")
        sessionStorage.setItem("originalstate", "{}")
        sessionStorage.setItem("borrowermenu", "loanSummary")
        this.props.history.push("/app/borrower/" + loanid)
    }
    bulkSubmit = (val) => {
        this.setState({ loading: true })
        let token = sessionStorage.getItem("ZeitroA")
        let body = null
        let api = ''
        if (this.state.bulkType === 'status') {
            body = { LoanIDs: this.state.selectedRowKeys, Status: val }
            api = '/los/bulkchangeloanstatus'
        } else {
            body = { LoanIDs: this.state.selectedRowKeys, LOID: val }
            api = '/los/bulkchangeloanofficer'
        }
        fetch(api, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache"
            }
        }).then(response => {
            this.setState({ loading: false })
            if (response.status !== 200) {
                return
            }
            this.reload()
            message.success(`change success`)
            this.setState({ selectedRowKeys: [], show: false })
        }).catch(function (err) {
            this.setState({ loading: false })
        })
    }
    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedRowKeys })
            }
        }
        let operateBtn = this.state.isArchivedLoans ? [
            {
                key: 'delete',
                tips: 'Unarchive loans'
            },
        ] : [
            {
                key: 'status',
                tips: 'Change selected status'
            },
            {
                key: 'loanofficer',
                tips: 'Change selected loan officer'
            },
            {
                key: 'addArchived',
                tips: 'Archive loans'
            }
        ]
        const dropdownItems = this.state.isArchivedLoans ? [
            {
                label: 'All Loans',
                key: 'all',
            },
        ] : [
            {
                label: 'Archived Loans',
                key: 'archived',
            }
        ]
        let columns = [
            {
                title: 'Loan Address',
                dataIndex: 'address',
                key: 'address',
                fixed: 'left',
                render: (text, record) => <div style={{ paddingLeft: '10px', backgroundColor: "inherit" }}>
                    {record.loanofficer === "Unassigned" || record.loanofficer === this.state.firstName + " " + this.state.lastName || record.loanprocessor === this.state.firstName + " " + this.state.lastName ?
                        <div className="clickableText" onClick={() => this.switchLoan(record.loanid)}>{record.address === "" ? "TBD" : this.processAddress(record.address)}</div>
                        :
                        <div className="unclickableText">{record.address === "" ? "TBD" : this.processAddress(record.address)}</div>
                    }
                </div>,

                filteredValue: [this.state.searchedText],
                onFilter: (value, record) => {
                    return (
                        String(record.address).toLowerCase().includes(value.toLowerCase()) ||
                        String(record.name).toLowerCase().includes(value.toLowerCase()) ||
                        String(record.email).toLowerCase().includes(value.toLowerCase()) ||
                        String(record.purpose).toLowerCase().includes(value.toLowerCase()) ||
                        String(record.status).toLowerCase().includes(value.toLowerCase()) ||
                        String(record.started).toLowerCase().includes(value.toLowerCase())
                    )
                },
                responsive: ['md'],

            },
            {
                title: 'Applicant',
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
                sorter: (a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                },

            },
            // {
            //     title: 'Email',
            //     dataIndex: 'email',
            //     key: 'email',
            //     sorter: (a, b) => {
            //         if (a.email < b.email) {
            //             return -1
            //         }
            //         if (a.email > b.email) {
            //             return 1
            //         }
            //         return 0
            //     }
            // },
            {
                title: 'Loan Purpose',
                dataIndex: 'purpose',
                key: 'purpose',
                sorter: (a, b) => {
                    if (a.purpose < b.purpose) {
                        return -1
                    }
                    if (a.purpose > b.purpose) {
                        return 1
                    }
                    return 0
                },

            },
            {
                title: 'Start Date',
                dataIndex: 'started',
                key: 'started',
                render: (text) => <>{text}</>,
                sorter: (a, b) => com.dateFromDate(a.started) - com.dateFromDate(b.started),
                defaultSortOrder: "descend",
                sortDirections: ["ascend", "descend", "ascend"],
                responsive: ['md'],
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                sorter: (a, b) => {
                    if (a.status < b.status) {
                        return -1
                    }
                    if (a.status > b.status) {
                        return 1
                    }
                    return 0
                },

                render: (text, record) => {
                    const items = Object.entries(com.loanStatusMap).map(([key, val]) => {

                        return {
                            key: key,
                            label: (
                                <a onClick={(e) => {
                                    e.stopPropagation()
                                    this.handleStatusChange(record.loanid, key)
                                }}>
                                    {val}
                                </a>
                            ),
                        }

                    })

                    return <div className="loansStatus">
                        <Tag
                            bordered={false}
                            color={com.mapStatusTagColor(record.status)}
                            className="loansStatusTag" >
                            {com.splitStatusByWords(record.status)}

                        </Tag>
                        <Dropdown
                            menu={{ items, style: { maxHeight: '300px', overflowY: 'auto' } }}
                            placement="bottomLeft"
                            arrow
                        >
                            <Button
                                className="loanStatusSelect"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    this.toggleEdit(record.loanid, 'status')
                                }}
                            >
                                <CaretDownOutlined style={{ fontSize: 11 }} />
                            </Button>
                        </Dropdown>
                    </div>
                },
                sortDirections: ["descend", "ascend", "descend"],
            },
            {
                title: "Loan Officer",
                dataIndex: "loanofficer",
                key: "loanofficer",
                sorter: (a, b) => {
                    if (a.loanofficer < b.loanofficer) {
                        return -1
                    }
                    if (a.loanofficer > b.loanofficer) {
                        return 1
                    }
                    return 0
                }
            },
            {
                width: 100,
                title: 'Archive',
                dataIndex: 'archive',
                key: 'archive',
                render: (text, record) => {
                    return !this.state.isArchivedLoans ? <img onClick={(event) => {
                        console.log(record)

                        event.stopPropagation()

                        this.changeArchived([record.loanid], 'add')

                    }} src="/images/archive.svg"></img> : <img
                        onClick={(event) => {
                            event.stopPropagation()
                            this.changeArchived([record.loanid], 'remove')

                        }}
                        src="/images/unDownload.svg"></img>
                }
            }



            // {
            //   title: 'Target Closing Date',
            //   dataIndex: 'started',
            //   key: 'started',
            //   sorter: (a, b) => {
            //     if (a.purpose < b.purpose) {
            //       return -1;
            //     }
            //     if (a.purpose > b.purpose) {
            //       return 1;
            //     }
            //     return 0;
            //   },

            // },

        ]
        // if (checkAccess([UR_Owner])) {
        //     columns.push(
        //         {
        //             title: "Loan Officer",
        //             dataIndex: "loanofficer",
        //             key: "loanofficer",
        //             sorter: (a, b) => {
        //                 if (a.loanofficer < b.loanofficer) {
        //                     return -1
        //                 }
        //                 if (a.loanofficer > b.loanofficer) {
        //                     return 1
        //                 }
        //                 return 0
        //             },

        //             render: (_, row) => (
        //                 <div>
        //                     {row.loanofficer === "Unassigned" ?
        //                         <Button
        //                             onClick={(e) => {
        //                                 e.stopPropagation()
        //                                 this.showAssignLO(row.id, row.loanid, row.name, row.loanofficer)
        //                             }}
        //                             className="loansAddLOButton">Add</Button>
        //                         :
        //                         <a
        //                             onClick={(e) => {
        //                                 e.stopPropagation()
        //                                 this.showAssignLO(row.id, row.loanid, row.name, row.loanofficer)
        //                             }}
        //                             style={{ textDecoration: "underline", marginRight: 40, fontWeight: 500 }}
        //                         >
        //                             {row.loanofficer}
        //                         </a>}

        //                 </div>
        //             ),
        //         },
        //         {
        //             title: 'Processor',
        //             dataIndex: 'loanprocessor',
        //             key: 'loanprocessor',
        //             sorter: (a, b) => {
        //                 if (a.loanprocessor < b.loanprocessor) {
        //                     return -1
        //                 }
        //                 if (a.loanprocessor > b.loanprocessor) {
        //                     return 1
        //                 }
        //                 return 0
        //             },

        //             responsive: ['md'],
        //             render: (_, row) => (
        //                 <div>
        //                     {row.loanprocessor === "Unassigned" ?
        //                         <Button
        //                             onClick={(e) => {
        //                                 e.stopPropagation()
        //                                 this.showAssignLP(row.id, row.loanid, row.name, row.loanprocessor)
        //                             }}
        //                             className="loansAddLOButton">Add</Button>
        //                         :
        //                         <a
        //                             onClick={(e) => {
        //                                 e.stopPropagation()
        //                                 this.showAssignLP(row.id, row.loanid, row.name, row.loanprocessor)
        //                             }}
        //                             style={{ textDecoration: "underline", marginRight: 40, fontWeight: 500 }}
        //                         >
        //                             {row.loanprocessor}
        //                         </a>}

        //                 </div>
        //             ),
        //         }
        //     )
        // }
        // if (this.ishardmoneyloancustomer) {
        //     columns.push({
        //         title: 'Underwriter',
        //         dataIndex: 'underwriter',
        //         key: 'underwriter',
        //         sorter: (a, b) => {
        //             if (a.underwriter < b.underwriter) {
        //                 return -1
        //             }
        //             if (a.underwriter > b.underwriter) {
        //                 return 1
        //             }
        //             return 0
        //         },

        //         responsive: ['md'],
        //         render: (_, row) => (
        //             <div>
        //                 {row.underwriter === "Unassigned" ?
        //                     <Button
        //                         onClick={(e) => {
        //                             e.stopPropagation()
        //                             this.showAssignUW(row.id, row.loanid, row.name, row.underwriter)
        //                         }}
        //                         className="loansAddLOButton">Add</Button>
        //                     :
        //                     <a
        //                         onClick={(e) => {
        //                             e.stopPropagation()
        //                             this.showAssignUW(row.id, row.loanid, row.name, row.underwriter)
        //                         }}
        //                         style={{ textDecoration: "underline", marginRight: 40, fontWeight: 500 }}
        //                     >
        //                         {row.underwriter}
        //                     </a>}

        //             </div>
        //         ),
        //     },
        //         {
        //             title: 'Closer',
        //             dataIndex: 'closer',
        //             key: 'closer',
        //             sorter: (a, b) => {
        //                 if (a.closer < b.closer) {
        //                     return -1
        //                 }
        //                 if (a.closer > b.closer) {
        //                     return 1
        //                 }
        //                 return 0
        //             },

        //             responsive: ['md'],
        //             render: (_, row) => (
        //                 <div>
        //                     {row.closer === "Unassigned" ?
        //                         <Button
        //                             onClick={() => this.showAssignCloser(row.id, row.loanid, row.name, row.closer)}
        //                             className="loansAddLOButton">Add</Button>
        //                         :
        //                         <a
        //                             onClick={() => this.showAssignCloser(row.id, row.loanid, row.name, row.closer)}
        //                             style={{ textDecoration: "underline", marginRight: 40, fontWeight: 500 }}
        //                         >
        //                             {row.closer}
        //                         </a>}

        //                 </div>
        //             ),
        //         })
        // }
        // console.log(this.getFilteredData(), 999)

        return (

            <>
                <div className="loansTable text-left  mx-0 w-100 " style={{ MinHeight: '100vh', background: "#f6F6F6" }}>
                    <div className="LoansTitle  d-flex justify-content-between align-items-center">
                        <div style={{ color: "#000", fontSize: 18, fontFamily: 'Inter', marginLeft: 24 }} >
                            {/* <span>{this.state.isArchivedLoans ? 'Archived Loans' : 'All Loans'}</span> */}
                            All Loans
                            {/* <Dropdown
                            className="document-dropdown"
                            menu={{
                                items: dropdownItems,
                                onClick: (e) => this.handleArchivedToggle(e)
                            }}
                            trigger={['click']}
                        >
                            <img style={{ width: 28, height: 28, cursor: 'pointer' }} className="document-more" src="/images/document-more.svg" />
                        </Dropdown> */}
                        </div>
                        <Button type="primary" style={{ marginRight: 24, fontSize: 16, fontFamily: 'Inter' }} onClick={() => {

                            this.setState({
                                openLoanPopup: true
                            })
                        }}>Create new loan</Button>
                    </div>
                    <div className="loansContent">
                        {
                            this.state.selectedRowKeys.length > 0 ? (
                                <div className="multiple-bar">
                                    <div className="document-total"><CloseOutlined onClick={() => this.setState({ selectedRowKeys: [] })} style={{ fontSize: 12, marginRight: 12, cursor: 'pointer' }} /> <span className="document-num">{this.state.selectedRowKeys.length} selected</span></div>
                                    <div className="document-operate">
                                        {
                                            operateBtn.map(item => {
                                                return (
                                                    <Tooltip placement="bottom" title={item.tips} arrow={false}>
                                                        <div className="document-operate-btn" onClick={() => this.handleOperate(item.key)}>
                                                            <img src={`/images/document-${item.key}.svg`} />
                                                        </div>
                                                    </Tooltip>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            ) : (
                                <div className="loansFilter">
                                    <div className="d-flex  align-items-center  w-50">
                                        <Input.Search
                                            className="newLoansSearchbar"
                                            placeholder="Search by any field"
                                            onSearch={value => this.setState({ searchedText: value })}
                                            allowClear
                                        />
                                        {checkAccess([UR_Owner]) &&
                                            <>
                                                <Select
                                                    className={this.ismobile ? "loansFilters-xs" : "newLoansFilters"}
                                                    placeholder="Loan Officer"
                                                    onChange={(value) => this.handleFilterChange('loanOfficer', value)}
                                                    allowClear
                                                >
                                                    {Array.from(new Set(Object.values(this.state.loanofficers))).map(s => (
                                                        <Select.Option key={s} value={s}>{s}</Select.Option>
                                                    ))}
                                                    <Select.Option key={"Unassigned"} value={"Unassigned"}>Unassigned</Select.Option>
                                                </Select>
                                                {/* <Select
                      className={this.ismobile ? "loansFilters-xs" : "loansFilters"}
                      placeholder="Loan Processor"
                      onChange={(value) => this.handleFilterChange('loanProcessor', value)}
                      allowClear
                    >
                      {Array.from(new Set(Object.values(this.state.loanprocessors))).map(s => (
                        <Select.Option key={s} value={s}>{s}</Select.Option>
                      ))}
                      <Select.Option key={"Unassigned"} value={"Unassigned"}>Unassigned</Select.Option>
                    </Select> */}
                                            </>
                                        }
                                        <Select
                                            className="newLoansFilters"
                                            placeholder="Loan Purpose"
                                            onChange={(value) => this.handleFilterChange('loanPurpose', value)}
                                            allowClear
                                        >
                                            {["Purchase", "Pre-qualification", "Refinance", "Cash-out Refinance", "TBD"].map(s => (
                                                <Select.Option key={s} value={s}>{s}</Select.Option>
                                            ))}
                                        </Select>
                                        <Select
                                            className="newLoansFilters"
                                            placeholder="Status"
                                            onChange={(value) => this.handleFilterChange('status', value)}
                                            allowClear
                                        >
                                            {/* {com.loanstatuses.map(s => (
                    <Select.Option key={s} value={s}>{com.loanStatusMap[s]}</Select.Option>
                  ))} */}
                                            {currentLoanStatuses.map(s => (
                                                <Select.Option key={s} value={s}>{com.loanStatusMap[s]}</Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    {/* this.handleLoansToggle */}
                                    <div className="viewMode-bar">
                                        <div style={{ fontWeight: 600, fontSize: 16, }}>
                                            View archived loans
                                            <Switch
                                                onChange={(

                                                    e
                                                ) => {

                                                    this.setState({ isArchivedLoans: e, selectedRowKeys: [] }, this.applyFilters)

                                                }}
                                                style={{ marginLeft: '10px' }}
                                            />
                                        </div>
                                        {/* <div style={{ fontWeight: 600, fontSize: 16, }}>
                                        View loans assigned to me only
                                        <Switch
                                            onChange={this.handleLoansToggle}
                                            style={{ marginLeft: '10px' }}
                                        />
                                    </div> */}
                                    </div>
                                </div>
                            )
                        }
                        {this.state.loading ?
                            <div>
                                <Skeleton active />
                                <Skeleton active />
                                <Skeleton active />
                            </div>
                            :
                            <div className="loansTableWrap">
                                <Table
                                    locale={{
                                        emptyText: (
                                            <div style={{ textAlign: 'center' }} className="emptyLoans">
                                                <img src='/images/empty.svg'></img>
                                                <div

                                                    style={{
                                                        margin: "10px auto",
                                                        color: '#6E6E70',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        width: 260

                                                    }}>
                                                    You currently have no loan records. Start a new one by clicking the button below.
                                                </div>
                                                <div style={{
                                                    margin: "10px auto",

                                                    width: 260

                                                }}>

                                                    <Button type="primary" style={{ fontSize: 16, fontFamily: 'Inter' }}
                                                        onClick={() => {

                                                            this.setState({
                                                                openLoanPopup: true
                                                            })
                                                        }}
                                                    >Create new loan</Button>
                                                </div>

                                            </div>
                                        ),
                                    }}
                                    className=" newLoansTable"
                                    rowClassName={(record, index) => {
                                        return record.loanofficer === "Unassigned" || record.loanofficer === this.state.firstName + " " + this.state.lastName || record.loanprocessor === this.state.firstName + " " + this.state.lastName ? "selectableBackground" : "unSelectableBackground"
                                    }}
                                    onRow={(record) => this.onRowClick(record)}
                                    columns={columns}
                                    dataSource={this.getFilteredData()}
                                    showSorterTooltip={false}
                                    rowKey={record => record.loanid}
                                    rowSelection={{
                                        type: 'checkbox',
                                        ...rowSelection,
                                    }}
                                    scroll={{
                                        x: true
                                    }}
                                    pagination={this.state.pagination}
                                    onChange={this.handleTableChange}
                                />
                            </div>
                        }
                        <AssignLoanOfficer
                            show={this.state.showassignlo}
                            name={this.state.name}
                            loName={this.state.loName}
                            onHide={this.hideAssignlo}
                            id={this.state.id}
                            loan_id={this.state.loan_id}
                            los_id={this.state.los_id}
                            loanofficers={this.state.loanofficers_full.map(t => {
                                return { id: t.id, firstname: t.firstname, lastname: t.lastname }
                            })}
                            handleClose={() => { this.setState({ showassignlo: false }) }}
                        />
                        <AssignLoanProcessor
                            show={this.state.showassignlp}
                            name={this.state.name}
                            lpName={this.state.lpName}
                            onHide={this.hideAssignlp}
                            id={this.state.id}
                            loan_id={this.state.loan_id}
                            lp_id={this.state.lpsid}
                            loanprocessors={this.state.allLoanProcessors.map(t => {
                                return { id: t.ID, firstname: t.FirstName, lastname: t.LastName }
                            })}
                            handleClose={() => { this.setState({ showassignlp: false }) }}
                        />
                        <AssignUnderwriter
                            show={this.state.showassignuw}
                            name={this.state.name}
                            uwName={this.state.underwriter}
                            onHide={this.hideAssignUw}
                            id={this.state.id}
                            loan_id={this.state.loan_id}
                            uw_id={this.state.underwriterid}
                            underwriters={this.state.allUnderwriters}
                            handleClose={() => { this.setState({ showassignuw: false }) }}
                        />
                        <AssignCloser
                            show={this.state.showassignCloser}
                            name={this.state.name}
                            uwName={this.state.closerName}
                            onHide={this.hideAssignCloser}
                            id={this.state.id}
                            loan_id={this.state.loan_id}
                            uw_id={this.state.closerid}
                            closer={this.state.allCloser}
                            handleClose={() => { this.setState({ showassignCloser: false }) }}
                        />
                        <Modal title="An internal error has occurred"
                            open={this.state.showErrorModal}
                            onOk={() => {
                                this.setState({ showErrorModal: false })
                                this.toggleEdit(null, null)
                                this.reload()
                            }}
                            onCancel={() => this.setState({ showErrorModal: false })}>
                            <p>Please try later or contact contact@zeitro.com for help.</p>
                        </Modal>
                        <BulkChangeModal
                            type={this.state.bulkType}
                            show={this.state.show}
                            options={this.state.loanofficers_full.map(t => {
                                return { id: t.id, firstname: t.firstname, lastname: t.lastname }
                            })}
                            handleSubmit={this.bulkSubmit}
                            handleClose={() => { this.setState({ show: false }) }}
                        />
                    </div>
                </div>
                <LoanBorrowerPopUp
                    submit={(value) => {
                        this.reload()
                        //   this.createNewApplication(value)
                        //         console.log(value)

                    }}
                    open={this.state.openLoanPopup} onClose={() => {

                        this.setState({
                            openLoanPopup: false
                        })
                    }}></LoanBorrowerPopUp>
            </>
        )
    }
}

export default withRouter(Borrower)
