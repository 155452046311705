
export const  CHANGE_APPLICATION = "CHANGE_APPLICATION";
export const  CHANGE_APPLICATION_BY_PATH = "CHANGE_APPLICATION_BY_PATH";

export const  CHANGE_HAS_COBORROWER = "CHANGE_HAS_COBORROWER";
export const  CHANGE_COHABITING = "CHANGE_COHABITING"
export const  CHANGE_APPLICATION_ATTRIBUTE = "CHANGE_APPLICATION_ATTRIBUTE"
export const  CHANGE_BORROWER_ATTRIBUTE = "CHANGE_BORROWER_ATTRIBUTE"
export const  CHANGE_HARDMONEYLOAN_ATTRIBUTE = "CHANGE_HARDMONEYLOAN_ATTRIBUTE"

export const  CHANGE_RENTOROWN_CURRENT_RESIDENCE = "CHANGE_RENTOROWN_CURRENT_RESIDENCE"
export const  CHANGE_FINANCING_SAMEPROPERTY = "CHANGE_FINANCING_SAMEPROPERTY"
export const  CHANGE_CHOSENLOAN_ATTRIBUTE = "CHANGE_CHOSENLOAN_ATTRIBUTE"

export const  CHANGE_NONQM_INFO = "CHANGE_NONQM_INFO"

export const  CHANGE_OCCUPATION_INFO = "CHANGE_OCCUPATION_INFO"
export const  CHANGE_PAST_OCCUPATION_INFO = "CHANGE_PAST_OCCUPATION_INFO"

export const  CHANGE_BORROWER_HASOCCUPATION = "CHANGE_BORROWER_HASOCCUPATION"
export const  ADD_ADDITIONAL_OCCUPATION = "ADD_BORROWER_ADDITIONAL_OCCUPATION"
export const  REMOVE_ADDITIONAL_OCCUPATION = "REMOVE_BORROWER_ADDITIONAL_OCCUPATION"
export const  CLEAR_ADDITIONAL_OCCUPATIONS = "CLEAR_BORROWER_ADDITIONAL_OCCUPATIONS"

export const  CHANGE_PREVIOUS_ADDRESS = "CHANGE_PREVIOUS_ADDRESS"
export const  ADD_PREVIOUS_ADDRESS = "ADD_PREVIOUS_ADDRESS"
export const  REMOVE_PREVIOUS_ADDRESS = "REMOVE_PREVIOUS_ADDRESS"
export const  CLEAR_PREVIOUS_ADDRESS = "CLEAR_PREVIOUS_ADDRESS"

export const CHANGE_INCOME_MONTHLY = "CHANGE_INCOME_MONTHLY"
export const CHANGE_INCOME_ATTRIBUTE = "CHANGE_INCOME_ATTRIBUTE"


export const CLEAR_ADDITIONAL_INCOME = "CLEAR_ADDITIONAL_INCOME"

export const ADD_ADDITIONAL_INCOME = "ADD_ADDITIONAL_INCOME"
export const REMOVE_ADDITIONAL_INCOME = "REMOVE_ADDITIONAL_INCOME"
export const HAS_ADDITIONAL_INCOME = "HAS_ADDITIONAL_INCOME"
export const CHANGE_ADDITIONAL_INCOME = "CHANGE_ADDITIONAL_INCOME"
export const CHANGE_EXPENSES = "CHANGE_EXPENSES"
export const CHANGE_JOINTLY = "CHANGE_JOINTLY"

export const CHANGE_FINANCES = "CHANGE_FINANCES"

export const ADD_BORROWER_BANKACCOUNT = "ADD_BORROWER_BANKACCOUNT"
export const BULKADD_BORROWER_BANKACCOUNTS = "BULKADD_BORROWER_BANKACCOUNTS"
export const REMOVE_BORROWER_BANKACCOUNT = "REMOVE_BORROWER_BANKACCOUNT"
export const CHANGE_BORROWER_BANKNAME = "CHANGE_BORROWER_BANKNAME"
export const CHANGE_BORROWER_BANKACCOUNT = "CHANGE_BORROWER_BANKACCOUNT"
export const CHANGE_BORROWER_BANKBALANCE = "CHANGE_BORROWER_BANKBALANCE"
export const CHANGE_BORROWER_BANKATTRIBUTION = "CHANGE_BORROWER_BANKATTRIBUTION"
export const CHANGE_BORROWER_BANKACCOUNTTYPE = "CHANGE_BORROWER_BANKACCOUNTTYPE"


export const CLEAR_BORROWER_BANKACCOUNTS = "CLEAR_BORROWER_BANKACCOUNTS"

export const ADD_BORROWER_BROKERACCOUNT = "ADD_BORROWER_BROKERACCOUNT"
export const CLEAR_BORROWER_BROKERACCOUNTS = "CLEAR_BORROWER_BROKERACCOUNTS"
export const REMOVE_BORROWER_BROKERACCOUNT = "REMOVE_BORROWER_BROKERACCOUNT"
export const CHANGE_BORROWER_BROKERNAME = "CHANGE_BORROWER_BROKERAGENAME"
export const CHANGE_BORROWER_BROKERACCOUNT = "CHANGE_BORROWER_BROKERACCOUNT"
export const CHANGE_BORROWER_BROKERBALANCE = "CHANGE_BORROWER_BROKERBALANCE"
export const CHANGE_BORROWER_BROKERATTRIBUTION = "CHANGE_BORROWER_BROKERATTRIBUTION"
export const CHANGE_BORROWER_BROKERTAXSTATUS = "CHANGE_BORROWER_BROKERTAXSTATUS"
export const CHANGE_BORROWER_BROKERALLOCATION = "CHANGE_BORROWER_BROKERALLOCATION"


export const CHANGE_BORROWER_ADD_PAPER = "CHANGE_BORROWER_ADD_PAPER"
export const CHANGE_BORROWER_DELETE_PAPER = "CHANGE_BORROWER_DELETE_PAPER"
export const CHANGE_BORROWER_CLEAR_PAPERS = "CHANGE_BORROWER_CLEAR_PAPERS"
export const CHANGE_BORROWER_PAPER_DESCRIPTION = "CHANGE_BORROWER_PAPER_DESCRIPTION"
export const CHANGE_BORROWER_PAPER_AMOUNT = "CHANGE_BORROWER_PAPER_AMOUNT"
export const CHANGE_BORROWER_PAPER_VALUE = "CHANGE_BORROWER_PAPER_VALUE"

export const ADD_BORROWER_AUTO = "ADD_BORROWER_AUTO"
export const REMOVE_BORROWER_AUTO = "REMOVE_BORROWER_AUTO"
export const CLEAR_BORROWER_AUTOS = "CLEAR_BORROWER_AUTOS"
export const CHANGE_BORROWER_AUTO_MAKE = "CHANGE_BORROWER_AUTO_MAKE"
export const CHANGE_BORROWER_AUTO_YEAR = "CHANGE_BORROWER_AUTO_YEAR"
export const CHANGE_BORROWER_AUTO_VALUE = "CHANGE_BORROWER_AUTO_VALUE"

export const ADD_BORROWER_OTHER_ASSET = "ADD_BORROWER_OTHER_ASSET"
export const BULKADD_BORROWER_OTHER_ASSETS = "BULKADD_BORROWER_OTHER_ASSETS"
export const REMOVE_BORROWER_OTHER_ASSET  = "REMOVE_BORROWER_OTHER_ASSET"
export const CLEAR_BORROWER_OTHER_ASSETS  = "CLEAR_BORROWER_OTHER_ASSETS"
export const CHANGE_BORROWER_OTHER_ASSET_NAME = "CHANGE_BORROWER_OTHER_ASSET_NAME"
export const CHANGE_BORROWER_OTHER_ASSET_VALUE = "CHANGE_BORROWER_OTHER_ASSET_VALUE"
export const CHANGE_BORROWER_OTHER_ASSET_ATTRIBUTION = "CHANGE_BORROWER_OTHER_ASSET_ATTRIBUTION"
export const CHANGE_BORROWER_OTHER_ASSET = "CHANGE_BORROWER_OTHER_ASSET"

export const ADD_BORROWER_LOAN = "ADD_BORROWER_LOAN"
export const BULKADD_BORROWER_LOANS = "BULKADD_BORROWER_LOANS"
export const REMOVE_BORROWER_LOAN = "REMOVE_BORROWER_LOAN"
export const CLEAR_BORROWER_LOANS = "CLEAR_BORROWER_LOANS"
export const COPY_BORROWER_LOAN = "COPY_BORROWER_LOAN"

export const CHANGE_BORROWER_LOAN_COMPANY = "CHANGE_BORROWER_LOAN_COMPANY"
export const CHANGE_BORROWER_LOAN_ADDRESS = "CHANGE_BORROWER_LOAN_ADDRESS"
export const CHANGE_BORROWER_LOAN_ACCOUNT = "CHANGE_BORROWER_LOAN_ACCOUNT"
export const CHANGE_BORROWER_LOAN_MONTHLY = "CHANGE_BORROWER_LOAN_MONTHLY"
export const CHANGE_BORROWER_LOAN_REMAINING = "CHANGE_BORROWER_LOAN_REMAINING"
export const CHANGE_BORROWER_LOAN_SATISFIEDUPON = "CHANGE_BORROWER_LOAN_SATISFIEDUPON"
export const CHANGE_BORROWER_LOAN_FORSUBJECT = "CHANGE_BORROWER_LOAN_FORSUBJECT"
export const CHANGE_BORROWER_LOAN_MONTHSLEFTTOPAY = "CHANGE_BORROWER_LOAN_MONTHSLEFTTOPAY"
export const CHANGE_BORROWER_LOAN_RATE= "CHANGE_BORROWER_LOAN_RATE"
export const CHANGE_BORROWER_LOAN = "CHANGE_BORROWER_LOAN"

export const CHANGE_BORROWER_LOAN_CORRECTION = "CHANGE_BORROWER_LOAN_CORRECTION"
export const CHANGE_BORROWER_LOAN_EXPLANATION = "CHANGE_BORROWER_LOAN_EXPLANATION"


export const BULKADD_BORROWER_OWNED_PROPERTIES = "BULKADD_BORROWER_OWNED_PROPERTIES"
export const ADD_BORROWER_OWNED_PROPERTY = "ADD_BORROWER_OWNED_PROPERTY"
export const PREPEND_BORROWER_OWNED_PROPERTY = "PREPEND_BORROWER_OWNED_PROPERTY"
export const REMOVE_BORROWER_OWNED_PROPERTY = "REMOVE_BORROWER_OWNED_PROPERTY"
export const CLEAR_BORROWER_OWNED_PROPERTY = "CLEAR_BORROWER_OWNED_PROPERTY"
export const CHANGE_OWNED_PROPERTY = "CHANGE_OWNED_PROPERTY"

export const ADD_BORROWER_ADDITIONAL_PROPERTY = "ADD_BORROWER_ADDITIONAL_PROPERTY"
export const REMOVE_BORROWER_ADDITIONAL_PROPERTY = "REMOVE_BORROWER_ADDITIONAL_PROPERTY"
export const CLEAR_BORROWER_ADDITIONAL_PROPERTY = "CLEAR_BORROWER_ADDITIONAL_PROPERTY"
export const CHANGE_ADDITIONAL_PROPERTY = "CHANGE_ADDITIONAL_PROPERTY"

export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_ADDRESS = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_ADDRESS"
export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_STATUS = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_STATUS"
export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_TYPE = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_TYPE"
export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_VALUE = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_VALUE"
export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_LIENS = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_LIENS"
export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_GROSSINCOME = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_GROSSINCOME"
export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_PAYMENT = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_PAYMENT"
export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_EXPENSES = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_EXPENSES"
export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_NETINCOME = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_NETINCOME"
export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_ATTRIBUTION = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_ATTRIBUTION"
export const CHANGE_BORROWER_ADDITIONAL_PROPERTY_ATTRIBUTE = "CHANGE_BORROWER_ADDITIONAL_PROPERTY_ATTRIBUTE"

export const ADD_BORROWER_ADDITIONAL_CREDITS = "ADD_BORROWER_ADDITIONAL_CREDITS"
export const REMOVE_BORROWER_ADDITIONAL_CREDITS = "REMOVE_BORROWER_ADDITIONAL_CREDITS"
export const CLEAR_BORROWER_ADDITIONAL_CREDITS = "CLEAR_BORROWER_ADDITIONAL_CREDITS"
export const CHANGE_BORROWER_ADDITIONAL_CREDIT_ALTNAME = "CHANGE_BORROWER_ADDITIONAL_CREDIT_ALTNAME"
export const CHANGE_BORROWER_ADDITIONAL_CREDIT_CREDITOR = "CHANGE_BORROWER_ADDITIONAL_CREDIT_CREDITOR"
export const CHANGE_BORROWER_ADDITIONAL_CREDIT_ACCOUNT = "CHANGE_BORROWER_ADDITIONAL_CREDIT_ACCOUNT"

export const CHANGE_BORROWER_FREEANDCLEAR = "CHANGE_BORROWER_FREEANDCLEAR"
export const CHANGE_REVIEWED_LOANS = "CHANGE_REVIEWED_LOANS"

export const CHANGE_SELECTION_FIELD = "CHANGE_SELECTION_FIELD"

export const CHANGE_SELECTEDLOAN_FIELD = "CHANGE_SELECTEDLOAN_FIELD"
export const CHANGE_SELECTEDLOAN_PRODUCT_FIELD = "CHANGE_SELECTEDLOAN_PRODUCT_FIELD"


export const ADD_SELECTEDLOAN_SERVICE = "ADD_SELECTEDLOAN_SERVICE"
export const REMOVE_SELECTEDLOAN_SERVICE = "REMOVE_SELECTEDLOAN_SERVICE"
export const ADD_SELECTEDLOAN_ESTIMATEDCLOSING_COSTS = "ADD_SELECTEDLOAN_ESTIMATEDCLOSING_COSTS"

export const ADD_SELECTEDLOAN_CHARGE = "ADD_SELECTEDLOAN_CHARGE"
export const REMOVE_SELECTEDLOAN_CHARGE = "REMOVE_SELECTEDLOAN_CHARGE"

export const CHANGE_PROPERTY_ADDRESS = "CHANGE_PROPERTY_ADDRESS"
export const CHANGE_PROPERTY_DESCRIPTION = "CHANGE_PROPERTY_DESCRIPTION"
export const CHANGE_PROPERTY_OCCUPANCY = "CHANGE_PROPERTY_OCCUPANCY"
export const CHANGE_PROPERTY_PURPOSE = "CHANGE_PROPERTY_PURPOSE"
export const CHANGE_PROPERTY_APPRAISAL = "CHANGE_PROPERTY_APPRAISAL"
export const CHANGE_PROPERTY_SALESPRICE = "CHANGE_PROPERTY_SALESPRICE"
export const CHANGE_PROPERTY_ATTRIBUTE = "CHANGE_PROPERTY_ATTRIBUTE"


export const CHANGE_PROPERTY_UNITS = "CHANGE_PROPERTY_UNITS"
export const CHANGE_PROPERTY_YEARBUILT = "CHANGE_PROPERTY_YEARBUILT"
export const CHANGE_PROPERTY_INWHATNAME  = "CHANGE_PROPERTY_INWHATNAME"
export const CHANGE_PROPERTY_INWHATMANNER  = "CHANGE_PROPERTY_INWHATMANNER"
export const CHANGE_PROPERTY_ESTATEHELDIN = "CHANGE_PROPERTY_ESTATEHELDIN"
export const CHANGE_PROPERTY_SOURCES = "CHANGE_PROPERTY_SOURCES"
export const CHANGE_PROPERTY_REFINANCE_YEARACQUIRED = "CHANGE_PROPERTY_REFINANCE_YEARACQUIRED"
export const CHANGE_PROPERTY_REFINANCE_ORIGINALCOST = "CHANGE_PROPERTY_REFINANCE_ORIGINALCOST"
export const CHANGE_PROPERTY_REFINANCE_PURPOSE = "CHANGE_PROPERTY_REFINANCE_PURPOSE"
export const CHANGE_PROPERTY_REFINANCE_IMPROVEMENTSDESC = "CHANGE_PROPERTY_REFINANCE_IMPROVEMENTSDESC"
export const CHANGE_PROPERTY_REFINANCE_IMPROVEMENTSSTATUS = "CHANGE_PROPERTY_REFINANCE_IMPROVEMENTSSTATUS"
export const CHANGE_PROPERTY_REFINANCE_COST = "CHANGE_PROPERTY_REFINANCE_COST"
export const CHANGE_PROPERTY_REFINANCE_EXPLAINOTHER = "CHANGE_PROPERTY_REFINANCE_EXPLAINOTHER"
export const CHANGE_PROPERTY_REFINANCE_ATTRIBUTE = "CHANGE_PROPERTY_REFINANCE_ATTRIBUTE"

export const CHANGE_PROPERTY_CONSTRUCTION_YEARACQUIRED = "CHANGE_PROPERTY_CONSTRUCTION_YEARACQUIRED"
export const CHANGE_PROPERTY_CONSTRUCTION_ORIGINALCOST = "CHANGE_PROPERTY_CONSTRUCTION_ORIGINALCOST"
export const CHANGE_PROPERTY_CONSTRUCTION_EXISTINGLIENS = "CHANGE_PROPERTY_CONSTRUCTION_EXISTINGLIENS"
export const CHANGE_PROPERTY_REFINANCE_EXISTINGLIENS = "CHANGE_PROPERTY_REFINANCE_EXISTINGLIENS"
export const CHANGE_PROPERTY_CONSTRUCTION_PRESENTVALUE = "CHANGE_PROPERTY_CONSTRUCTION_PRESENTVALUE"
export const CHANGE_PROPERTY_CONSTRUCTION_COSTOFIMPROVEMENTS = "CHANGE_PROPERTY_CONSTRUCTION_COSTOFIMPROVEMENTS"

export const CHANGE_DECLARATIONS = "CHANGE_DECLARATIONS"
export const CHANGE_BORROWER_INFO = "CHANGE_BORROWER_INFO"
export const CHANGE_MAINPROPERTY_EXPIRATION = "CHANGE_MAINPROPERTY_EXPIRATION"
export const CHANGE_EQUAL_OPPORTUNITY = "CHANGE_EQUAL_OPPORTUNITY"
export const CHANGE_EQUAL_OPPORTUNITY_SUB = "CHANGE_EQUAL_OPPORTUNITY_SUB"
export const CHANGE_MAINPROPERTY_PROPERTYTYPE = "CHANGE_MAINPROPERTY_PROPERTYTYPE"
export const CHANGE_MAINPROPERTY = "CHANGE_MAINPROPERTY"
export const CHANGE_MOBILE_HOME_INFO = "CHANGE_MOBILE_HOME_INFO"


export const ADD_BORROWER_ALIMONY = "ADD_BORROWER_ALIMONY"
export const BULKADD_BORROWER_ALIMONYS = "BULKADD_BORROWER_ALIMONYS"
export const REMOVE_BORROWER_ALIMONY = "REMOVE_BORROWER_ALIMONY"
export const CLEAR_BORROWER_ALIMONY = "CLEAR_BORROWER_ALIMONY"
export const CHANGE_BORROWER_ALIMONY_AMOUNT = "CHANGE_BORROWER_ALIMONY_AMOUNT"
export const CHANGE_BORROWER_ALIMONY_OWEDTO = "CHANGE_BORROWER_ALIMONY_OWEDTO"
export const CHANGE_BORROWER_ALIMONY_TYPE = "CHANGE_BORROWER_ALIMONY_TYPE"
export const CHANGE_BORROWER_ALIMONY = "CHANGE_BORROWER_ALIMONY"

export const CHANGE_BORROWER_CLEAR_INSURANCE = "CHANGE_BORROWER_CLEAR_INSURANCE"
export const CHANGE_BORROWER_DELETE_INSURANCE = "CHANGE_BORROWER_DELETE_INSURANCE"
export const CHANGE_BORROWER_ADD_INSURANCE = "CHANGE_BORROWER_ADD_INSURANCE"
export const CHANGE_BORROWER_CHANGE_INSURANCE = "CHANGE_BORROWER_CHANGE_INSURANCE"

export const ADD_REMOTE_COBORROWER = "ADD_REMOTE_COBORROWER"
export const REMOVE_REMOTE_COBORROWER = "REMOVE_REMOTE_COBORROWER"
export const CLEAR_REMOTE_COBORROWER = "CLEAR_REMOTE_COBORROWER"
export const CHANGE_REMOTE_COBORROWER_FIRSTNAME = "CHANGE_REMOTE_COBORROWER_FIRSTNAME"
export const CHANGE_REMOTE_COBORROWER_LASTNAME = "CHANGE_REMOTE_COBORROWER_LASTNAME"
export const CHANGE_REMOTE_COBORROWER_EMAIL = "CHANGE_REMOTE_COBORROWER_EMAIL"
export const CHANGE_REMOTE_COBORROWER_STATUS = "CHANGE_REMOTE_COBORROWER_STATUS"
export const CHANGE_REMOTE_COBORROWER_USE = "CHANGE_REMOTE_COBORROWER_USE"

export const CHANGE_SOLAR_PANELS = "CHANGE_SOLAR_PANELS"

export const ADD_BORROWER_OTHER_LIEN = "ADD_BORROWER_OTHER_LIEN"
export const REMOVE_BORROWER_OTHER_LIEN = "REMOVE_BORROWER_OTHER_LIEN"
export const CLEAR_BORROWER_OTHER_LIENS = "CLEAR_BORROWER_OTHER_LIENS"
export const CHANGE_BORROWER_OTHER_LIEN_VALUE = "CHANGE_BORROWER_OTHER_LIEN_VALUE"

export const ADD_BORROWER_OTHER_OCCUPATION = "ADD_BORROWER_OTHER_OCCUPATION"
export const REMOVE_BORROWER_OTHER_OCCUPATION = "REMOVE_BORROWER_OTHER_OCCUPATION"
export const CLEAR_BORROWER_OTHER_OCCUPATIONS = "CLEAR_BORROWER_OTHER_OCCUPATIONS"
export const CHANGE_BORROWER_OTHER_OCCUPATION_VALUE = "CHANGE_BORROWER_OTHER_OCCUPATION_VALUE"
export const CHANGE_BORROWER_OTHER_OCCUPATION_INCOME = "CHANGE_BORROWER_OTHER_OCCUPATION_INCOME"


export const ADD_BORROWER_PREVIOUS_OCCUPATION = "ADD_BORROWER_PREVIOUS_OCCUPATION"
export const REMOVE_BORROWER_PREVIOUS_OCCUPATION = "REMOVE_BORROWER_PREVIOUS_OCCUPATION"
export const CLEAR_BORROWER_PREVIOUS_OCCUPATIONS = "CLEAR_BORROWER_PREVIOUS_OCCUPATIONS"
export const CHANGE_BORROWER_PREVIOUS_OCCUPATION_VALUE = "CHANGE_BORROWER_PREVIOUS_OCCUPATION_VALUE"
export const CHANGE_BORROWER_PREVIOUS_OCCUPATION_INCOME = "CHANGE_BORROWER_PREVIOUS_OCCUPATION_INCOME"


export const UPDATE_FICO = "UPDATE_FICO"
export const UPDATE_CR_ERRORS = "UPDATE_CR_ERRORS"
export const CLEAR_CR_ERRORS = "CLEAR_CR_ERRORS"

export const UPDATE_CANNOTSHOPSERVICES = "UPDATE_CANNOTSHOPSERVICES"
export const UPDATE_CANSHOPSERVICES = "UPDATE_CANSHOPSERVICES"
export const UPDATE_FEES = "UPDATE_FEES"
export const UPDATE_ORIGINALCOSTS = "UPDATE_ORIGINALCOSTS"
export const UPDATE_OTHERCOSTS = "UPDATE_OTHERCOSTS"


export const UPDATE_PAYMENT_PERIOD = "UPDATE_PAYMENT_PERIOD"
export const UPDATE_PAYMENT_AMOUNT = "UPDATE_PAYMENT_AMOUNT"
export const UPDATE_PREPAID_DEFAULT = "UPDATE_PREPAID_DEFAULT"

export const UPDATE_PREPAID_INTEREST = "UPDATE_PREPAID_INTEREST"

export const ADD_SELECTEDLOAN_PREPAID = "ADD_SELECTEDLOAN_PREPAID"
export const REMOVE_SELECTEDLOAN_PREPAID = "REMOVE_SELECTEDLOAN_PREPAID"

export const ADD_BORROWER_GIFT = "ADD_BORROWER_GIFT"
export const BULKADD_BORROWER_GIFTS = "ADD_BORROWER_GIFTS"
export const REMOVE_BORROWER_GIFT = "REMOVE_BORROWER_GIFT"
export const CLEAR_BORROWER_GIFT = "CLEAR_BORROWER_GIFT"
export const CHANGE_BORROWER_GIFT = "CHANGE_BORROWER_GIFT"

export const ADD_BORROWER_ADDITIONAL_PROPERTIES_CREDIT = "ADD_BORROWER_ADDITIONAL_PROPERTIES_CREDIT"
export const ADD_BORROWER_ADDITIONAL_PROPERTIES_ATTRIBUTES = "ADD_BORROWER_ADDITIONAL_PROPERTIES_ATTRIBUTES"

export const BULKADD_NEW_MORTGAGE_LOAN = "BULKADD_NEW_MORTGAGE_LOAN"
export const ADD_NEW_MORTGAGE_LOAN = "ADD_NEW_MORTGAGE_LOAN"
export const REMOVE_NEW_MORTGAGE_LOAN = "REMOVE_NEW_MORTGAGE_LOAN"
export const CLEAR_NEW_MORTGAGE_LOANS = "CLEAR_NEW_MORTGAGE_LOANS"
export const UPDATE_NEW_MORTGAGE_ATTRIBUTE = "UPDATE_NEW_MORTGAGE_ATTRIBUTE"