import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Row, Col, Form, Input, Upload, Button, Spin, message } from 'antd'
import CropperModal from '../../../Photo/CropperModal';
import "./Company.css"
const { TextArea } = Input
const CompanySetup = forwardRef(({ info, finish, change, changeProgress }, ref) => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage()
    const [loading, setLoading] = useState(false)
    const [cropperVisible, setCropperVisible] = useState(false)
    const [imageSrc, setImageSrc] = useState('');
    const [showUploadBtn, setShowUploadBtn] = useState(false)
    useEffect(() => {
        getCompanyProfile()
    }, [])
    const getCompanyProfile = () => {
        setLoading(true)
        let token = sessionStorage.getItem("ZeitroA")
        fetch("/los/getcompanyprofilesetup", {
          cache: 'no-cache',
          method: 'GET',
          headers: {
            Authorization: "Bearer " + token,
            Cache: "no-cache"
          },
        }).then(response => {
          if (!response.ok) {
            setLoading(false);
          } else {
            response.json().then(js => {
                setLoading(false)
                let data = js
                let formdata = {
                    file: data.Logo !== '' ? [
                        {
                            url: data.Logo
                        }
                    ] : [],
                    companyWebsite: data.Name,
                    companyLink: data.Link,
                    banner: data.Banner,
                    companyIntroduction: data.Introduction,
                    companyAddress: data.Address,
                    companyPhone: data.Phone,
                    noreplyEmail: data.NoreplyEmail,
                    supportEmail: data.SupportEmail,
                }
                setShowUploadBtn(data.Logo === '')
                form.setFieldsValue(formdata)
            })
          }
        }).catch(error => {
            setLoading(false)
            console.log("Revalidate catch error " + error)
        })
    }

    const setCompanyProfile = (data) => {
        setLoading(true)
        let token = sessionStorage.getItem("ZeitroA")
        fetch('/los/updatecompanyprofilesetup', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + token,
        },
        }).then(
        response => {
            setLoading(false)
            if (response.status !== 200) {
                return;
            }
            getCompanyProfile()
            message.success('Update Success')
        }
        ).catch(err => {
            setLoading(false)
            console.log('Fetch Error :-S', err);
        })
    }
    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
        }
    }
    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (e) => resolve(e.target.result)
            // reader.onload = (e) => resolve(e.target.result.replace("data:", "").replace(/^.+,/, ""))
            reader.onerror = (error) => reject(error)
        })
    }

    const handleUploadChange = async ({ file, fileList }) => {
        if (file.status === 'done') {
            const base64Data = await readFileAsDataURL(file.originFileObj)
            const newFileList = fileList.map((f) => {
                if (f.uid === file.uid) {
                    return { ...f, url: base64Data }
                }
                return f
            })
            form.setFieldsValue({
                file: newFileList,
            })
            setImageSrc(base64Data)
            setCropperVisible(true)
        } else if (file.status === 'error') {

        }
    }
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    }
    const handleCustomRequest = (options) => {
        const { onSuccess } = options
        onSuccess()
    }
    const uploadBtn = () => {
        return showUploadBtn && <div className='upload-btn'><img src='/images/upload-img.svg' /></div>
    }
    const handleCropper = () => {
        const base64Data = form.getFieldValue('file')[0].url
        setImageSrc(base64Data)
        setCropperVisible(true)
    }
    const itemRender = (originNode, file, fileList, actions) => (
        <div style={{ marginTop: '6px', display: 'flex' }}>
            <img onClick={handleCropper} className='profile-photo' src={ file.url }></img>
            <img className='remove-photo' src='/images/remove.svg' onClick={() => { actions.remove(file) }} />
        </div>
    )
    const handleValuesChange = async (changedValues, allValues) => {
    
        
        if ( changedValues?.file?.length > 0) {
            setShowUploadBtn(false)
        } else {
            setShowUploadBtn(true)
        }
    }
    const validateForm = () => {
        form.validateFields().then((values) => {
            let data = {
                Name: values.companyWebsite,
                Banner: values.banner,
                Introduction: values.companyIntroduction,
                Link: values.companyLink,
                Logo: values.file.length > 0 ? values.file[0].url : '',
                Address: values.companyAddress,
                Phone: values.companyPhone,
                NoreplyEmail: values.noreplyEmail,
                SupportEmail: values.supportEmail,
            }
            setCompanyProfile(data)
        }).catch((errorInfo) => {

            console.log('Validation failed:', errorInfo);
        })
    }
    const handleSave = (croppedImage) => {
        const formValues = form.getFieldValue()
        let imgList = form.getFieldValue('file')
        const newFileList = imgList.map((f) => {
            return { url: croppedImage }
        })
        form.setFieldsValue({
            file: newFileList,
        })
    }
    return (
        <div className="company-info-setup p-4">
            {contextHolder}
            <Spin spinning={loading}>
                <div className='form-wrap'>
                    <Form
                        className='company-info-form'
                        layout='vertical'
                        form={form}
                        onValuesChange={handleValuesChange}
                        validateMessages={validateMessages}
                        size="large"
                    >
                        <Row gutter={32}>
                            <Col span={24} sm={14}>
                                <Form.Item
                                    name="companyWebsite"
                                    label="Company name"
                                >
                                    <Input placeholder="" maxLength={40}/>
                                </Form.Item>
                                <Form.Item
                                    name="companyLink"
                                    label="Company website link"
                                >
                                    <Input placeholder="" maxLength={200}/>
                                </Form.Item>
                            </Col>
                            <Col span={24} sm={10}>
                                <Form.Item
                                    name="file"
                                    label="Company logo"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //     },
                                    // ]}
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                >
                                    <Upload
                                        maxCount={1}
                                        customRequest={handleCustomRequest}
                                        itemRender={itemRender}
                                        onChange={handleUploadChange}
                                    >
                                        {uploadBtn()}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={32}>
                            <Col span={12} sm={10}>
                                <Form.Item
                                    name = "companyAddress"
                                    label="Company address"
                                >
                                    <Input placeholder="" maxLength={200}/>
                                </Form.Item>
                            </Col>
                            <Col span={12} sm={10}>
                                <Form.Item
                                    name="companyPhone"
                                    label="Company phone"
                                >
                                    <Input placeholder="" maxLength={20}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={32}>
                            <Col span={12} sm={10}>
                                <Form.Item
                                    name="noreplyEmail"
                                    label="Company's noreply email"
                                >
                                    <Input placeholder="" maxLength={200}/>
                                </Form.Item>
                            </Col>
                            <Col span={12} sm={10}>
                                <Form.Item
                                    name="supportEmail"
                                    label="Company's support email"
                                >
                                    <Input placeholder="" maxLength={200}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name="banner"
                            label="Company banner"
                        >
                            <Input placeholder="" maxLength={200}/>
                        </Form.Item>
                        <Form.Item
                            name="companyIntroduction"
                            label="Company introduction "
                        >
                            <TextArea placeholder="" maxLength={200}/>
                        </Form.Item>
                    </Form>
                    <Button type="primary" onClick={validateForm}>
                        Submit
                    </Button>
                </div>
            </Spin>
            <CropperModal
                visible={cropperVisible}
                imageSrc={imageSrc}
                onClose={() => setCropperVisible(false)}
                onSave={handleSave}
            />
        </div>
    );
})
export default CompanySetup;