import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react'
import {
    LeftOutlined,
    MinusOutlined,
    PlusOutlined,
    RightOutlined,
} from '@ant-design/icons'
import { Input, Skeleton } from 'antd'
import { Document, Page } from 'react-pdf'
import { VariableSizeList as List } from 'react-window'
import './PDFViewer.css'
import Worker from './pdfWorker.js'

const MIN_SCALE = 0.5
const MAX_SCALE = 3
const SCALE_STEP = 0.1
const PAGE_GAP = 0

function PDFViewer(props) {
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(props.pageNumber || 1)
    const [inputPageNumber, setInputPageNumber] = useState(
        String(props.pageNumber) || '1'
    )
    const [scale, setScale] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [pages, setPages] = useState([])
    const containerRef = useRef(null)
    const pageRefs = useRef({})
    const listRef = useRef(null)
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })
    const [initialPageLoaded, setInitialPageLoaded] = useState(false)

    const calculateScale = useCallback((page, containerWidth, containerHeight) => {
        if (!page) return 1

        const pageWidth = page.viewport.width
        const pageHeight = page.viewport.height

        const scaleX = containerWidth / pageWidth
        const scaleY = containerHeight / pageHeight

        let newScale = Math.min(scaleX, scaleY)
        newScale = Math.max(newScale, MIN_SCALE)
        newScale = Math.min(newScale, MAX_SCALE)

        return newScale
    }, [])

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect
                // Check if containerRef.current exists before accessing its properties
                if (containerRef.current) {
                    // Subtract scrollbar width from container width
                    const scrollbarWidth = containerRef.current.offsetWidth - containerRef.current.clientWidth
                    setContainerSize({ width: width - scrollbarWidth, height })
                }
            }
        })

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current)
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current)
            }
        }
    }, [])

    useEffect(() => {
        if (initialPageLoaded && pages.length > 0) {
            const newScale = calculateScale(pages[0], containerSize.width, containerSize.height)
            setScale(newScale)
        }
    }, [containerSize, initialPageLoaded, pages, calculateScale])

    useEffect(() => {
        const processFile = async () => {
            if (props.file && !initialPageLoaded) {
                setIsLoading(true)
                const result = await Worker.processFile(props.file, 1)
                if (result.type === 'success') {
                    setNumPages(result.numPages)
                    setPages(result.pages)
                    const initialScale = calculateScale(result.pages[0], containerSize.width, containerSize.height)
                    setScale(initialScale)
                    setIsLoading(false)
                    setInitialPageLoaded(true)
                } else if (result.type === 'error') {
                    console.error('Error loading PDF:', result.error)
                    setIsLoading(false)
                }
            }
        }

        processFile()
    }, [props.file, calculateScale, containerSize, initialPageLoaded])

    const getItemSize = useCallback(
        (index) => {
            if (!pages[index]) return 0
            const { height } = pages[index].viewport
            return height * scale + PAGE_GAP
        },
        [pages, scale]
    )

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0)
        }
    }, [scale, pages])

    const handlePreviousPage = useCallback(() => {
        setPageNumber((prevPageNumber) => {
            const newPageNumber = Math.max(prevPageNumber - 1, 1)
            props.setPageNumber(newPageNumber)
            setInputPageNumber(newPageNumber.toString())
            return newPageNumber
        })
    }, [props])

    const handleNextPage = useCallback(() => {
        setPageNumber((prevPageNumber) => {
            const newPageNumber = Math.min(prevPageNumber + 1, numPages || 1)
            props.setPageNumber(newPageNumber)
            setInputPageNumber(newPageNumber.toString())
            return newPageNumber
        })
    }, [numPages, props])

    const handleZoomIn = () => {
        setScale((prevScale) => Math.min(prevScale + SCALE_STEP, MAX_SCALE))
    }

    const handleZoomOut = () => {
        setScale((prevScale) => Math.max(prevScale - SCALE_STEP, MIN_SCALE))
    }

    const handleWheel = useCallback((event) => {
        if (event.ctrlKey || event.metaKey) {
            event.preventDefault()
            const delta = event.deltaY || event.detail || event.wheelDelta
            if (delta < 0) {
                handleZoomIn()
            } else if (delta > 0) {
                handleZoomOut()
            }
        }
    }, [])

    let touchStartY = 0
    let touchStartDistance = 0

    function handleTouchStart(e) {
        if (e.touches.length === 2) {
            touchStartDistance = Math.hypot(
                e.touches[0].pageX - e.touches[1].pageX,
                e.touches[0].pageY - e.touches[1].pageY
            )
        } else if (e.touches.length === 1) {
            touchStartY = e.touches[0].pageY
        }
    }

    function handleTouchMove(e) {
        if (e.touches.length === 2) {
            e.preventDefault()
            const distance = Math.hypot(
                e.touches[0].pageX - e.touches[1].pageX,
                e.touches[0].pageY - e.touches[1].pageY
            )
            const delta = distance - touchStartDistance
            if (Math.abs(delta) > 10) {
                if (delta > 0) {
                    handleZoomIn()
                } else {
                    handleZoomOut()
                }
                touchStartDistance = distance
            }
        }
    }

    function handlePageNumberChange(e) {
        const value = e.target.value
        if (value === '') {
            setInputPageNumber('')
            return
        }
        if (/^\d+$/.test(value)) {
            let newValue = parseInt(value, 10)
            if (newValue === 0) {
                setInputPageNumber('')
            } else if (newValue > numPages) {
                setInputPageNumber(numPages.toString())
            } else {
                setInputPageNumber(newValue.toString())
            }
        }
    }

    function handlePageNumberKeyPress(e) {
        if (e.key === 'Enter') {
            const newPage = parseInt(inputPageNumber, 10) || 1
            if (newPage >= 1 && newPage <= numPages) {
                setPageNumber(newPage)
                props.setPageNumber(newPage)
                scrollToPage(newPage)
            } else {
                setInputPageNumber(pageNumber.toString())
            }
        }
    }

    function handlePageNumberBlur() {
        if (
            inputPageNumber === '' ||
            parseInt(inputPageNumber, 10) < 1 ||
            parseInt(inputPageNumber, 10) > numPages
        ) {
            setInputPageNumber(pageNumber.toString())
        }
    }

    function scrollToPage(page) {
        if (listRef.current) {
            listRef.current.scrollToItem(page - 1, 'start')
        }
    }

    useEffect(() => {
        const container = containerRef.current
        if (container) {
            container.addEventListener('wheel', handleWheel, { passive: false })
            container.addEventListener('touchstart', handleTouchStart)
            container.addEventListener('touchmove', handleTouchMove, {
                passive: false,
            })
        }
        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel)
                container.removeEventListener('touchstart', handleTouchStart)
                container.removeEventListener('touchmove', handleTouchMove)
            }
        }
    }, [])

    useEffect(() => {
        setInputPageNumber(String(props.pageNumber))
        if (listRef.current && pages.length > 0) {
            listRef.current.scrollToItem(props.pageNumber - 1, 'start')
            setPageNumber(props.pageNumber)
        }
    }, [props.pageNumber, pages])

    const updatePageNumber = useCallback(
        (newPageNumber) => {
            if (newPageNumber !== pageNumber) {
                setPageNumber(newPageNumber)
                setInputPageNumber(newPageNumber.toString())
                props.setPageNumber(newPageNumber)
            }
        },
        [pageNumber, props]
    )

    const handleScroll = useCallback(() => {
        if (listRef.current) {
            const listElement = listRef.current._outerRef
            const scrollTop = listElement.scrollTop
            const clientHeight = listElement.clientHeight

            let totalHeight = 0
            let currentPage = 1

            for (let i = 0; i < pages.length; i++) {
                const pageHeight = getItemSize(i)
                const pageTop = totalHeight
                const pageBottom = pageTop + pageHeight

                // Check if 50% of the page is in the visible area
                if (scrollTop + clientHeight > pageTop + pageHeight * 0.5) {
                    currentPage = i + 1
                } else {
                    break
                }

                totalHeight += pageHeight
            }

            // Check if scrolled to the last page
            if (scrollTop + clientHeight >= listElement.scrollHeight - 10) {
                currentPage = numPages
            }

            // Update page number without triggering scroll
            if (currentPage !== pageNumber) {
                setPageNumber(currentPage)
                setInputPageNumber(currentPage.toString())
            }
        }
    }, [pages, getItemSize, pageNumber, numPages])

    const Row = useCallback(
        ({ index, style }) => {
            const page = pages[index]
            if (!page) return null

            return (
                <div
                    style={{
                        ...style,
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        minWidth: `${page.viewport.width * scale}px`,
                        paddingBottom: `${PAGE_GAP}px`,
                        height: `${style.height - PAGE_GAP}px`,
                    }}
                    ref={(el) => (pageRefs.current[index + 1] = el)}
                    data-page-number={index + 1}
                >
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        scale={scale}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        loading={
                            <div style={{ visibility: 'hidden' }}></div>
                        }
                    />
                </div>
            )
        },
        [pages, scale, props.file]
    )

    return (
        <div className="pdf-container" ref={containerRef} onWheel={handleWheel}>
            {isLoading && (
                <div
                    className="p-2"
                    style={{ minHeight: '200px', width: '100%' }}
                >
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                </div>
            )}
            {!isLoading && (
                <div style={{
                    width: containerSize.width,
                    height: containerSize.height,
                    overflow: 'auto',
                    position: 'relative'
                }}>
                    <Document file={props.file}>
                        {numPages && (
                            <List
                                ref={listRef}
                                width={containerSize.width}
                                height={containerSize.height}
                                itemCount={numPages}
                                itemSize={getItemSize}
                                onScroll={handleScroll}
                                layout="vertical"
                                style={{
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    minWidth: '100%'
                                }}
                            >
                                {Row}
                            </List>
                        )}
                    </Document>
                </div>
            )}
            {!isLoading && (
                <div className="pdf-controls">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '30px',
                        }}
                    >
                        <button
                            onClick={handlePreviousPage}
                            disabled={pageNumber <= 1}
                            className="pdf-controls-button"
                            style={{
                                width: '20px',
                                height: '20px',
                                marginRight: '8px',
                            }}
                        >
                            <LeftOutlined />
                        </button>
                        <Input
                            value={inputPageNumber}
                            onChange={handlePageNumberChange}
                            onKeyPress={handlePageNumberKeyPress}
                            onBlur={handlePageNumberBlur}
                            className="pdf-controls-input"
                            placeholder="1"
                        />
                        <span style={{ marginLeft: '8px' }}>/{numPages}</span>
                        <button
                            onClick={handleNextPage}
                            disabled={pageNumber >= numPages}
                            className="pdf-controls-button"
                            style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '8px',
                            }}
                        >
                            <RightOutlined />
                        </button>
                    </div>
                    <div className="pdf-controls-divider"></div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '30px',
                        }}
                    >
                        <button
                            onClick={handleZoomOut}
                            className="pdf-controls-button"
                            style={{
                                width: '30px',
                                height: '30px',
                            }}
                            disabled={scale <= MIN_SCALE}
                        >
                            <MinusOutlined />
                        </button>
                        <span style={{ marginLeft: '8px', marginRight: '8px' }}>
                            {Math.round(scale * 100)}%
                        </span>
                        <button
                            onClick={handleZoomIn}
                            className="pdf-controls-button"
                            style={{
                                width: '30px',
                                height: '30px',
                            }}
                            disabled={scale >= MAX_SCALE}
                        >
                            <PlusOutlined />
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PDFViewer