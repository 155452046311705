import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover, Upload, message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { ESP_Gmail, ESP_Bluehost, ESP_Outlook, ESP_PersonalGmail, ESP_PersonalOutlook } from '../../../State'

export default function StepOne({ currentProvider }) {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [providerType, setProviderType] = useState('')
    const [accountType, setAccountType] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [bConfigs, setBConfigs] = useState('')
    const [DataInfo, setDataInfo] = useState({})
    const { open, showLenders, step, customerLenders, showAchievement, showPricing, first, selectLenders } = useSelector(state => {


        return state.manager
    })



    const onFinish = (value) => {
        if (providerType == 'gmail' && accountType == 'Gmail' && bConfigs == '') {
            message.error('File is not uploaded')
            return
        }
        const token = sessionStorage.getItem("ZeitroA")


        const body = JSON.stringify({

            Keys: bConfigs,
            ClientID: value.ClientID,
            EmailProvider: value.provider == "Bluehost" ? "Bluehost" : value.EmailProvider,
            Password: value.Password,
            Secret: value.Secret,
            SingleEmail: value.SingleEmail,
            TenantID: value.TenantID

        })
        fetch('/los/updateemailconfigs', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
            },
            body,
        })
        dispatch({ type: "STEP", data: step + 1 })
        message.success('Email information is updated successfully')
    }
    const getEmail = async () => {
        let token = sessionStorage.getItem("ZeitroA")

        const response = await fetch('/los/getemailconfigs', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                Cache: "no-cache",
            }
        })
        const data = await response.json()

        const provider = {
            Outlook: 'Outlook',
            PersonalOutlook: 'Outlook',
            Gmail: 'gmail',
            PersonalGmail: "gmail",
            Bluehost: 'Bluehost'


        }
        setProviderType(provider[data['EmailProvider']])
        setAccountType(data['EmailProvider'])

        form.setFieldsValue({
            EmailProvider: data['EmailProvider'],
            ClientID: data['ClientID'],
            Password: data['Password'],
            Secret: data['Secret'],
            SingleEmail: data['SingleEmail'],
            TenantID: data['TenantID'],
            provider: provider[data['EmailProvider']]
        })
        setDataInfo(data)


    }
    useEffect(() => {
        getEmail()

    }, [])
    useEffect(() => {
        // getEmail()
        if (providerType == '') {

            currentProvider(false)
        } else {

            currentProvider(true)
        }

    }, [providerType])
    const getType = () => {

        if (providerType == 'gmail' || providerType == 'Outlook') {
            return true

        } else {
            return false
        }
    }
    const getAccount = () => {

        if (providerType == 'gmail' && accountType == 'Gmail') {
            return false
        }
        if (providerType == 'gmail' && accountType == 'PersonalGmail') {
            return true
        }
        if (providerType == 'Outlook' && accountType == 'Outlook') {
            return true
        }
        if (providerType == 'Outlook' && accountType == 'PersonalOutlook') {
            return true
        }
        if (providerType == 'Bluehost') {
            return true
        }
    }
    return (
        <>
            <div className='ModalTitle'>
                Setup email communication
            </div>
            <div style={{ marginTop: 15, fontSize: 16, fontFamily: "Inter", fontWeight: 400 }}>
                All emails to borrowers currently come from a Zeitro email address. It is highly recommended to set up your email settings so the emails will come from your email address instead. You can also add later in <span style={{

                    fontWeight: 600
                }}>
                    Management {'>'}  Communication.

                </span>
                {(accountType == 'PersonalGmail' || accountType == 'PersonalOutlook') && <span onClick={() => {
                    setIsModalOpen(true)
                }} style={{ color: '#325CEB', cursor: 'pointer', marginLeft: 5, textDecoration: 'underline' }}>
                    2-step verification instruction
                </span>}
            </div>
            <div className='company-info-form-popUp' >
                <Form form={form} layout="vertical" onFinish={onFinish} >
                    <Row gutter={32}>
                        <Col span={12} sm={12}>
                            <Form.Item
                                label="Email provider"
                                name="provider"

                            >
                                <Select style={{ width: '100%' }} onChange={(e) => {
                                    setProviderType(e)
                                    setAccountType('')
                                    form.setFieldsValue({
                                        EmailProvider: ''
                                    })
                                }}>
                                    <option value='gmail'>Gmail</option>
                                    <option value={ESP_Outlook}>Outlook</option>

                                    <option value={ESP_Bluehost}>Blueshost</option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {getType() && <Col span={12} sm={12}>
                            <Form.Item
                                label="Account type"
                                name="EmailProvider"

                            >
                                <Select style={{ width: '100%' }} onChange={(
                                    v
                                ) => {

                                    setAccountType(v)
                                }}>
                                    <option value={providerType == 'Outlook' ? ESP_Outlook : ESP_Gmail}>Company</option>
                                    <option value={providerType == 'Outlook' ? ESP_PersonalOutlook : ESP_PersonalGmail}>Personal</option>


                                </Select>
                            </Form.Item>
                        </Col>}
                    </Row>
                    <div style={{ marginTop: 15 }}>
                        {

                            (!(accountType == 'PersonalGmail' || accountType == 'PersonalOutlook') && (accountType !== '') && providerType !== 'Bluehost') &&
                            <a style={{
                                color: '#325CEB', textDecoration: 'underline',

                                fontSize: 16,


                            }} onClick={() => {
                                setIsModalOpen(true)
                            }}>View full instruction</a>
                        }
                    </div>
                    {providerType == 'gmail' && accountType == 'Gmail' ?
                        <>

                            <form onSubmit="return false;">
                                <input type='file' id='input-uploader' style={{ opacity: 0 }} onChange={(e) => {
                                    let uploaderInput = document.getElementById("input-uploader")

                                    const file = uploaderInput.files[0]
                                    let reader = new FileReader()
                                    let base64String

                                    reader.onload = function () {
                                        base64String = reader.result.replace("data:", "").replace(/^.+,/, "")
                                        setBConfigs(base64String)

                                        message.success('upload success')
                                    }
                                    reader.readAsDataURL(file)


                                }}></input>
                                <Button style={{
                                    borderRadius: 6,
                                    border: '1px solid #E3EAFD',
                                    background: '#F7F8FF',
                                    color: "#325CEB",
                                    textAlign: "center",
                                    fontFamily: "Inter",
                                    fontWeight: 600,
                                    fontSize: 16,
                                    fontVariantNumeric: 'lining-nums proportional-nums',
                                    height: 32,



                                }} onClick={() => {
                                    let uploaderInput = document.getElementById("input-uploader")
                                    uploaderInput.click()


                                }}>Upload key file</Button>
                            </form>
                        </> : <></>
                    }
                    {
                        getAccount() ?
                            <>
                                <Row gutter={32}>
                                    <Col span={12} sm={12}>
                                        <Form.Item
                                            label={accountType == 'Outlook' || accountType == 'Gmail' ? "Client ID" : 'Single outbound email address'}
                                            name={accountType == 'Outlook' || accountType == 'Gmail' ? "ClientID" : 'SingleEmail'}
                                            rules={
                                                accountType == 'Outlook' || accountType == 'Gmail' ?
                                                    false : [
                                                        {
                                                            type: 'email',
                                                            message: 'The input is not a valid email!',
                                                        },
                                                    ]}
                                        >
                                            <Input placeholder='Example: xxxx@outlook.com' style={{
                                                height: 44
                                            }}></Input>
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Row gutter={32}>
                                    <Col span={12} sm={12}>
                                        <Form.Item
                                            label={accountType == 'Outlook' || accountType == 'Gmail' ? "Client credentials (secret value)" : 'Password'}
                                            name={accountType == 'Outlook' || accountType == 'Gmail' ? "Secret" : 'Password'}
                                        >
                                            <Input type={(accountType == 'Outlook' || accountType == 'Gmail') ? '' : 'password'} style={{
                                                height: 44
                                            }}></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} sm={12}>
                                        <Form.Item
                                            dependencies={['Password']}
                                            label={accountType == 'Outlook' || accountType == 'Gmail' ? "Directory ID (tenant ID)" : 'Verify password'}
                                            name={accountType == 'Outlook' || accountType == 'Gmail' ? "TenantID" : 'Verify'}
                                            rules={accountType == 'Outlook' || accountType == 'Gmail' ? false : [

                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('Password') === value) {
                                                            return Promise.resolve()
                                                        }
                                                        return Promise.reject(new Error('The new password that you entered do not match!'))
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input type={(accountType == 'Outlook' || accountType == 'Gmail') ? '' : 'password'} style={{
                                                height: 44
                                            }}></Input>
                                        </Form.Item>
                                    </Col>

                                </Row></> : <>



                            </>
                    }
                    <Button type='primary' htmlType='submit' id='emailForm' style={{ opacity: 0 }}></Button>
                </Form>

            </div >
            <Modal className="custom-modal-type" footer={(accountType == 'Gmail' || accountType == 'Outlook') && <div style={{ marginRight: 60, cursor: 'pointer' }}>

                <img src='/images/popCopy.svg' onClick={async () => {
                    const id = accountType == 'Gmail' ? 'gmailModal' : 'OutLookModal'
                    let text = document.getElementById(id).innerHTML

                    const cleanedHtml = text.replace(/<[^>]+>/g, '')
                    console.log(cleanedHtml)
                    await navigator.clipboard.writeText(cleanedHtml)
                    message.success('Copied to clipboard')

                }}></img>
            </div>} open={isModalOpen} onOk={() => {
                setIsModalOpen(true)
            }} onCancel={() => {
                setIsModalOpen(false)
            }}>
                {accountType == 'Outlook' && OutLookModal()}
                {accountType == 'Gmail' && GmailModal()}
                {accountType == 'PersonalOutlook' && OutLookPersonal()}
                {accountType == 'PersonalGmail' && GailPersonal()}

            </Modal>

        </>

    )
    function OutLookModal() {
        return (<>
            <div id='OutLookModal' style={{
                height: "450px",
                overflowY: "scroll",

                color: '#6E6E70',
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Inter",
                marginTop: 30,
                marginRight: 17,
                marginLeft: 44,
                marginBottom: 20
            }}>
                <div style={{
                    marginRight: 27
                }}>

                    <div className='StepTitle' style={{
                        fontSize: 24, color: '#222',

                        fontWeight: 600
                    }}>Outlook setup instruction</div>
                    <div style={{
                        color: '#6E6E70',
                        padding: '10px  0'
                    }}>
                        We highly recommend to send this instruction for your IT team or <span style={{
                            color: '#325ceb'
                        }}>

                            contact us
                        </span> to setup for you.
                    </div>
                    <ol className='GailPersonal'>
                        <li className='StepTitle'> App registration</li >
                        <ol type='a' className='GailPersonal'>
                            <li>Sign in to <a style={{
                                color
                                    : '#325CEB'
                            }} href="https://portal.azure.com/" target='_blank' rel="noopener noreferrer">Microsoft Azure Poral</a> using your corporate admin email address.</li>
                            <li>In the search bar at the top of the page, search for "App registrations" and select.</li>
                            <li>Select "new registration"</li>
                            <li>In the "Name" input box, enter "zeitro"</li>
                            <li>Under the question "Who can use this application or access this API?", select "Accounts in this organizational directory only".</li>
                            <li>Click on <span className='boldText'>"Register"</span> </li>
                        </ol>
                        <li className='StepTitle'> Enable Microsoft API permissions for Zeitro to send email through your business account.</li >
                        <ol type='a' className='GailPersonal'>
                            <li>Still in the "App registration" page, select the "All applications" tab.</li>
                            <li>Click open the app named  <span className='boldText'>"zeitro"</span>, which you just registered.</li>
                            <li>Under the title "Build your application with the Microsoft identity platform", select "View API permission".</li>
                            <li>In the "Configured permissions" section, select "Add a permission".</li>
                            <li>In the tab "Microsoft APIs", select "Microsoft Graph"</li>
                            <li>Select "Application permissions"</li>
                            <li>Scroll down until you find the "Mail" category and then expand it.</li>
                            <li>Check the "Mail.Send" and "Mail.ReadWrite" permissions.</li>
                            <li>Scroll down to the very bottom until you find the "User" category and then expand it.</li>
                            <li>Check the "User.Read.All" permissions.</li>
                            <li>Select "Add permissions".</li>
                            <li>Now you should be back to the "API permissions" page, select "Grant admin consent for Zeitro.</li>
                            <li>Select "Yes" in the pop up window.</li>
                            <li>Wait a few seconds for the website to load the changes.</li>
                        </ol>
                        <li className='StepTitle'> Copy and submit app credentials</li >
                        <ol type='a' className='GailPersonal'>
                            <li>On the left sidebar, select "Overview".</li>
                            <li>In the "Essentials" section, copy the value of "Application (client) ID", and paste it into the correponding input box below.</li>
                            <li>In the "Essentials" section, copy the value of "Directory (tenant) ID", and paste it into the correponding input box below.</li>
                            <li>In the "Essentials" section, click open the "Client credentials".</li>
                            <li>Now you should be in the "Certificates & secrets" page, and make sure your are in "Client secrets" tab.</li>
                            <li>Select "New client secret</li>
                            <li>In the pop up window on the right side, enter "zeitro-key" into the "Description" input box.</li>
                            <li>Select "730 days" for the "Expires" box.</li>
                            <li>Click <span className='boldText'> "Add"</span>.</li>
                            <li>Now you should see a record inserted into the table under "Client secrets" tab, in "Certificates & secrets" page.</li>
                            <li>Copy the text under the "Value" column of the table, and paste it into the corresponding input box below.</li>
                        </ol>
                        <li className='StepTitle'>Click on the "Complete" button.</li >
                        <div style={{ color: "#325CEB" }}>
                            <div style={{ marginTop: 15 }}> <span style={{ fontWeight: 600, }}>
                                *Reminder
                            </span>: Please send an email to us at <span style={{ color: "#325CEB", fontWeight: "600" }}>andrew.zhang@zeitro.com</span> to inform us that you have finished the setup. </div>
                            <div style={{ marginTop: 15 }}>Addtionally, in the email, please also specify the "noreply" email address and the "support" email address that you would like to use for system communication.</div>
                        </div>
                    </ol>

                </div >

            </div >
        </>)
    }
    function GmailModal() {

        return (

            <div id='gmailModal' style={{
                height: "450px",
                overflowY: "scroll",
                color: "#6E6E70",
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Inter",
                marginTop: 30,
                marginRight: 17,
                marginLeft: 44,
                marginBottom: 20
            }}>
                <div style={{
                    marginRight: 27,

                }}>
                    <div style={{
                        color: "#222",
                        fontFamily: "Inter",
                        fontSize: 24,
                        fontWeight: 600
                    }}>Email setup instruction</div>
                    <div style={{
                        color: "#6E6E70",
                        fontSize: 16,
                        fontWeight: 400,
                        fontFamily: "Inter",

                    }}>
                        We highly recommend to send this instruction for your IT team or <span style={{
                            color: "#325CEB"
                        }}>contact us</span> to setup for you.
                    </div>
                    <ol className='GailPersonal'>
                        <li className='StepTitle' style={{ marginTop: 15 }}> Create a project</li>
                        <ol type='a' className='GailPersonal'>
                            <li>Sign in to <a style={{
                                color
                                    : '#325CEB'
                            }} href="https://console.cloud.google.com/" target='_blank' rel="noopener noreferrer">Google Cloud Platform console</a> using your corporate email address.</li>
                            <li>Create a new project, using your domain as a value for organization field.</li>
                        </ol>
                        <li className='StepTitle'> Enable API for Gmail</li>
                        <ol type='a' className='GailPersonal'>
                            <li>Select the project from a drop-down list at the top of the page.</li>
                            <li>Go to APIs, click on enable APIs and services.</li>
                            <li>Search for Gmail and click on Gmail API from the search results.</li>
                            <li>Enable Gmail API.</li>
                        </ol>
                        <li className='StepTitle'>Create a service account and private keys</li>
                        <ol type='a' className='GailPersonal'>
                            <li>Go to the <a style={{
                                color
                                    : '#325CEB'
                            }} href="https://console.cloud.google.com/iam-admin/serviceaccounts" target='_blank' rel="noopener noreferrer">Service Accounts</a> page. Make sure you are still using same email address and the project is selected in the drop-down list at the top of the page.</li>
                            <li>Click <span className='boldText'>Create Service Account.</span></li>
                            <li>Fill in the form, only <span className='boldText'>Service account name</span> and <span className='boldText'>Service account ID</span> fields are mandatory.</li>
                            <li>Click on the email of a newly created service account in the list.</li>
                            <li>Go to <span className='boldText'>keys</span> tab.</li>
                            <li>Click on  <span className='boldText'>"Add key" </span>&#8594; <span className='boldText'>"Create new key" </span> &#8594; select JSON and click create &#8594; download the file.</li>
                            <p style={{
                                color
                                    : "#325CEB",
                                fontWeight: 600,
                                fontSize: 16,
                                fontFamily: 'Inter'
                            }}>Warning: Anybody, who has access to this file or it's content will be able to send and recieve emails on behalf of people within your organization.</p>
                            <li>Upload downloaded file below. We will syphered content and store it. This configurations will be used to send emails on behalf of people within your organization.</li>
                            {/* <Form.Group controlId="JSONconfig" className={bConfigs === '' && submitted ? `${inputs['invalid-input']} mb-3` : 'mb-3'}>
                <Form.Control ref={configRef} type="file" onChange={configUploadHandler} />
              </Form.Group> */}
                            <li>Go to <span className='boldText'>  details tab. </span></li>
                            <li>Copy  <span className='boldText'> Unique ID (Client ID) </span> .</li>
                        </ol>
                        <li className='StepTitle'>Grant permissions to the service account in G-Suit</li>
                        <ol type='a' className='GailPersonal'>
                            <li>Sign in to <a style={{
                                color
                                    : '#325CEB'
                            }} href='http://admin.google.com/' target='_blank' rel="noopener noreferrer">G-Suite admin portal</a>  using your G-Suite admin account.</li>
                            <li>Go to  <span className='boldText'>Service </span>.</li>
                            <li>Click <span className='boldText'> API controls </span>.</li>
                            <li>Click <span className='boldText'> Manage Domain Wide Deligation </span> .</li>
                            <li>Click  <span className='boldText'>Add new</span></li>
                            <li>Use Unique ID (Client ID) from 3.i.</li>
                            <li>Add following API Scopes (without quotes) : "https://www.googleapis.com/auth/gmail.metadata", "https://www.googleapis.com/auth/gmail.modify". For you reference, list of scopes and explanations to them: <a style={{
                                color
                                    : '#325CEB'
                            }} href='https://developers.google.com/gmail/api/auth/scopes' target='_blank' rel="noopener noreferrer">here</a></li>
                        </ol>
                        <li className='StepTitle'>Click on the "Complete" button.</li>

                        <div style={{
                            color
                                : "#325CEB",
                            fontWeight: 400,
                            fontSize: 16,
                            fontFamily: 'Inter'
                        }}>
                            <div style={{ marginTop: 15 }}> <span style={{ fontWeight: 600 }}>
                                *Reminder
                            </span>: Please send an email to us at <span style={{ color: "#325CEB", fontWeight: "600" }}>andrew.zhang@zeitro.com</span> to inform us that you have finished the setup. </div>
                            <div style={{ marginTop: 15 }}>Addtionally, in the email, please also specify the "noreply" email address and the "support" email address that you would like to use for system communication.</div>
                        </div>
                    </ol>
                </div>

            </div>
        )
    }
    function OutLookPersonal() {
        return (

            <div style={{
                height: "450px",
                overflowY: "scroll",
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Inter",
                marginTop: 30,
                marginRight: 17,
                marginLeft: 44,
                marginBottom: 20
            }}>
                <div style={{
                    marginRight: 27
                }}>
                    <div className='StepTitle' style={{
                        color: '#222',
                        fontSize: '24px',
                        fontWeight: 600,
                        fontFamily: "Inter",

                    }}>Create & use App Passwords</div>

                    <ol className='GailPersonal' style={{ color: '#6E6E70', fontFamily: "Inter", fontSize: 16, fontWeight: 400 }}>
                        <li> Sign in your Outlook Account.<span style={{ color: "#325CEB", textDecoration: "underline", cursor: "pointer" }} onClick={() => window.open("https://account.microsoft.com", "_blank")}>https://account.microsoft.com</span></li>
                        <li>On the left side bar, select <span className='boldText'> "Security"</span>.</li>
                        <li> In the Security page, under open the <span className='boldText'> "Account"</span> category.</li>
                        <li> Click on <span className='boldText'>"Manage how I sign in"</span>.</li>
                        <li> After it redirects you to the new page, locate the section <span className='boldText'>"Additional security"</span></li>
                        <li> Check if the <span className='boldText'>"Two-step verification"</span> is turned on. If not, turn it on.</li>
                        <li> After you turn on "Two-step verification", you should be able to see the<span className='boldText'> "App passwords" </span>in the lower part of the page.</li>
                        <li>Click on<span className='boldText'>"Create a new app password"</span> .</li>
                        <li> Copy the generated password to the input field in here.</li>
                        <li>Click on the <span className='boldText'>"Complete"</span> button in here, and the set up is done. </li>
                    </ol>
                </div>
            </div>
        )


    }
    function GailPersonal() {
        return (
            <div style={{
                height: "450px",
                overflowY: "scroll",
                fontSize: 16,
                fontWeight: 400,
                fontFamily: "Inter",
                marginTop: 30,
                marginRight: 17,
                marginLeft: 44,
                marginBottom: 20
            }}>
                <div style={{
                    marginRight: 27
                }}>
                    <div className='StepTitle' style={{
                        color: '#222',
                        fontSize: '24px',
                        fontWeight: 600

                    }}>Create & use App Passwords</div>
                    <div style={{ color: '#6E6E70', fontFamily: "Inter", fontSize: 16, fontWeight: 400 }}>


                        <ol className='GailPersonal'>
                            {/* <li>If you use 2-Step-Verification, please enter an App Password.</li> */}
                            <li> Go to your Google Account.
                                <span style={{ color: "#325CEB", textDecoration: "underline", cursor: "pointer" }} onClick={() => window.open("https://myaccount.google.com/", "_blank")}>https://myaccount.google.com/</span>


                            </li>
                            <li>Select <span className='boldText'>Security</span>.</li>
                            <li> Under "<span className='boldText'>Signing in to Google</span>," select App Passwords. You may need to sign in. If you don’t have this option, it might be because:

                                <ol type='a' style={{ marginLeft: 20 }}>
                                    <li>2-Step Verification is not set up for your account.</li>
                                    <li> 2-Step Verification is only set up for security keys.</li>
                                    <li> Your account is through work, school, or other organization.</li>
                                    <li> You turned on Advanced Protection.</li>
                                </ol>

                            </li>
                            <li> At the bottom, choose Select app and choose<span className='boldText'>"Mail"</span> and then Select device and choose "Other" and then Generate.</li>
                            <li> Follow the instructions to enter the App Password. The App Password is the 16-character code in the yellow bar on your device.
                                Tap Done. And copy the generated password to input field below.</li>
                        </ol>

                    </div>
                </div>



            </div>
        )
    }
}
