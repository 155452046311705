import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import { Form, Input, Button, Col, Row, Modal, Table, Tag, Select, Radio, Popover, InputNumber, message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { capitalize, divideStringByCapitalLetter, getLoInfo, getUserId, splitByCapitalLetter } from '../../../Common'
import {
    CloseCircleOutlined,


    CheckCircleOutlined
} from '@ant-design/icons'


export default function StepTwo(props) {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [dataSource, setDataSource] = useState([])
    const [loading, setLoading] = useState(false)
    const [editingKey, setEditingKey] = useState('')
    const [isSameNMLS, setIsSameNMLS] = useState(false)
    const [isSameEmail, setIsSameEmail] = useState(false)
    const [isNext, setIsNext] = useState(false)
    const [showGrayAdd, setShowGrayAdd] = useState(false)
    const [roleSelect, setRoleSelect] = useState('')
    const [isVerify, setIsVerify] = useState({
        Email: true,
        FirstName: true,
        LastName: true,
        Nmls: true,
        Roles: true
    })
    const { step } = useSelector(state => {


        return state.manager
    })
    const isEditing = (record) => record.ID === editingKey
    const getInput = (data, title) => {
        if (data = undefined) {
            return <Input placeholder='Required' style={{ height: 44 }} />
        }


        if (isSameEmail && title === 'Email') {
            return <Input style={{ height: 44 }} status='error' className="custom-input"
                suffix={<img src='/images/x.svg' />}
                placeholder='Required' />
        }
        if (isSameNMLS && title == 'NMLS') {
            return <Input

                style={{ height: 44 }} status='error' className="custom-input" placeholder='Required' />
        }

        if (!isVerify.Email && title == 'Email') {
            return <Input onFocus={() => {

            }} onChange={(e) => {
                setIsVerify({
                    ...isVerify,
                    Email: e.target.value == '' ? false : true
                })
                const time = setTimeout(() => {
                    const email = document.getElementById('Email')
                    email.focus()
                    clearTimeout(time)
                }, 100)



            }} suffix={<img src='/images/x.svg' />} style={{ height: 44 }} status='error' className="custom-input" placeholder='Required' />
        }
        if (!isVerify.Nmls && title == 'NMLS' && (roleSelect == 'PriceEngineUser' || roleSelect == 'LoanOfficer' || roleSelect == 'Owner')) {
            return <Input
                onFocus={() => {
                    form.setFieldsValue({
                        Nmls: '',
                    })
                }}
                onChange={(e) => {
                    setIsVerify({
                        ...isVerify,
                        Nmls: e.target.value == '' ? false : true
                    })
                    const time = setTimeout(() => {
                        const email = document.getElementById('Nmls')
                        email.focus()
                        clearTimeout(time)
                    }, 100)

                }} suffix={<img src='/images/x.svg' />} style={{ height: 44 }} status='error' className="custom-input" placeholder='Required' />
        }
        if (!isVerify.FirstName && title == 'First Name') {
            return <Input
                onFocus={() => {
                    form.setFieldsValue({
                        FirstName: '',
                    })
                }}
                onChange={(e) => {
                    setIsVerify({
                        ...isVerify,
                        FirstName: e.target.value == '' ? false : true
                    })
                    const time = setTimeout(() => {
                        const email = document.getElementById('First Name')
                        email.focus()
                        clearTimeout(time)
                    }, 100)

                }} suffix={<img src='/images/x.svg' />} style={{ height: 44 }} status='error' className="custom-input" placeholder='Required' />
        }
        if (!isVerify.LastName && title == 'Last Name') {
            return <Input
                onFocus={() => {
                    form.setFieldsValue({
                        LastName: '',
                    })
                }}
                onChange={(e) => {
                    setIsVerify({
                        ...isVerify,
                        LastName: e.target.value == '' ? false : true
                    })
                    const time = setTimeout(() => {
                        const email = document.getElementById('Last Name')
                        email.focus()
                        clearTimeout(time)
                    }, 100)

                }}
                suffix={<img src='/images/x.svg' />} style={{ height: 44 }} status='error' className="custom-input" placeholder='Required' />
        }
        if (title === 'Role') {
            if (!isVerify.Roles) {
                return <Select
                    suffixIcon={<img src='/images/x.svg' />}
                    status='error'
                    className="custom-input"
                    placeholder='Required'
                    onChange={(e) => {
                        setIsVerify({
                            ...isVerify,
                            Roles: e == '' ? false : true
                        })
                        setRoleSelect(e)
                        // if ((e == 'PriceEngineUser' || e == 'LoanOfficer' || e == 'Owner')) {
                        //     form.setFieldsValue({
                        //         Nmls: 'Required'
                        //     })
                        // } else {
                        //     form.setFieldsValue({
                        //         Nmls: ' '
                        //     })
                        // }

                    }}
                    options={[
                        {
                            label: "Loan officer",
                            value: "LoanOfficer"

                        },
                        {
                            label: "Manager",
                            value: "Owner"

                        },

                        {
                            label: "Loan processor",
                            value: "LoanProcessor"

                        },
                        {
                            label: "Price engine user",
                            value: "PriceEngineUser"

                        }

                    ]}>

                </Select>
            } else {
                return <Select
                    placeholder='Required'
                    onChange={(e) => {
                        setRoleSelect(e)
                    }}
                    options={[
                        {
                            label: "Loan officer",
                            value: "LoanOfficer"

                        },
                        {
                            label: "Manager",
                            value: "Owner"

                        },

                        {
                            label: "Loan processor",
                            value: "LoanProcessor"

                        },
                        {
                            label: "Price engine user",
                            value: "PriceEngineUser"

                        }

                    ]}>

                </Select>
            }

        }
        if (title == 'NMLS') {
            return <Input placeholder={(roleSelect == 'PriceEngineUser' || roleSelect == 'LoanOfficer' || roleSelect == 'Owner') ? 'Required' : 'Optional'} style={{ height: 44 }} />

        }
        return <Input placeholder='Required' id={title} style={{ height: 44 }} />
    }
    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {



        const inputNode = getInput(record, title)


        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}

                        style={{
                            margin: 0,
                        }}

                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        )
    }
    let columns = [

        {
            title: 'First Name',
            editable: true,
            dataIndex: 'FirstName',
            key: 'FirstName',
            width: 120,

            sorter: (a, b) => {
                if (a.Email < b.Email) {
                    return -1
                }
                if (a.Email > b.Email) {
                    return 1
                }
                return 0
            },
            render: (text) => <>{text}</>,
            className: "loansFixedColumn"
        },
        {
            title: 'Last Name',
            editable: true,
            dataIndex: 'LastName',
            key: 'LastName',
            width: 120,

            sorter: (a, b) => {
                if (a.Email < b.Email) {
                    return -1
                }
                if (a.Email > b.Email) {
                    return 1
                }
                return 0
            },
            render: (text) => <>{text}</>,
            className: "loansFixedColumn"
        },
        {
            title: 'Email',
            editable: true,
            dataIndex: 'Email',
            key: 'Email',

            width: 120,
            render: (text) => <>{text}</>,
            sorter: (a, b) => {
                if (a.Email < b.Email) {
                    return -1
                }
                if (a.Email > b.Email) {
                    return 1
                }
                return 0
            },
            onFilter: (value, record) => {
                return (
                    String(record.Email).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.FullName).toLowerCase().includes(value.toLowerCase()) ||
                    String(record.Nmls).toLowerCase().includes(value.toLowerCase())

                )
            },
            className: "loansFixedColumn"
        },

        {
            title: 'Role',
            editable: true,
            dataIndex: 'Roles',
            key: 'Roles',
            width: 100,
            sorter: (a, b) => {
                if (a.Roles?.join(',') < b.Roles?.join(',')) {
                    return -1
                }
                if (a.Roles?.join(',') > b.Roles?.join(',')) {
                    return 1
                }
                return 0
            },
            render: (text) => <div title={text?.join(',')} style={{
                width: "150px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
            }}>{text?.map(splitByCapitalLetter).join(',')}</div>,
            className: "loansFixedColumn"
        },
        {
            title: 'NMLS',
            editable: true,
            dataIndex: 'Nmls',
            key: 'Nmls',
            width: 120,
            render: (text) => <>{text}</>,
            sorter: (a, b) => {
                if (a.Nmls < b.Nmls) {
                    return -1
                }
                if (a.Nmls > b.Nmls) {
                    return 1
                }
                return 0
            },
            className: "loansFixedColumn",
        },
        // {
        //     title: 'Account Tier',
        //     // editable: true,
        //     dataIndex: 'AccountTier',
        //     width: 120,
        //     key: 'AccountTier',
        //     render: (text) => <>{capitalize(text)}</>,
        //     className: "loansFixedColumn",
        //     sorter: (a, b) => a.AccountTier.localeCompare(b.AccountTier),
        // },
        {

            key: 'Status',
            className: "loansFixedColumn",
            width: 80,

            render: (text, record, a) => <>
                {(record.ID == '' && !showGrayAdd) ? <div>

                    <img id='imgAdd' src='/images/ok.svg' style={{ cursor: "pointer", fontSize: 16 }} onClick={async () => {



                        if (editingKey !== '') return
                        save(record.ID)

                    }} />
                    <span id='spanId' onClick={async () => {

                        const row = await form.validateFields()
                        console.log(row)
                        let flag = false
                        Object.keys(row).forEach(key => {
                            if (row[key] !== undefined && row[key] !== '') {
                                flag = true
                            }
                        })
                        if (flag) {
                            message.error("Please save the filled content")
                        } else {

                            dispatch({ type: "SHOW_ACHIEVEMENT", data: false })
                            dispatch({ type: "STEP", data: step + 1 })
                        }
                    }}></span>

                </div> : <div style={{ cursor: "pointer", fontSize: 16 }}>
                    {
                        editingKey === record.ID ? <div>
                            <img src='/images/delete.svg' onClick={() => {
                                form.setFieldsValue({
                                    Email: '',
                                    FirstName: '',
                                    LastName: '',
                                    Nmls: '',
                                    Roles: undefined,
                                    ID: ''
                                })
                                setEditingKey('')
                                setShowGrayAdd(false)
                            }}></img>
                            {/* <CloseCircleOutlined onClick={() => {
                                setEditingKey('')
                            }} /> */}
                            <img src='/images/ok.svg' style={{ color: '#325CEB', marginLeft: 10 }} onClick={() => {

                                save(record.ID)



                            }}></img>
                            {/* <CheckCircleOutlined
                                onClick={() => {
                                    save(record.ID)
                                }}

                                 /> */}
                        </div> :
                            <img src='/images/edit.svg' onClick={() => {
                                setIsVerify({
                                    Email: true,
                                    FirstName: true,
                                    LastName: true,
                                    Nmls: true,
                                    Roles: true
                                })
                                setShowGrayAdd(false)
                                edit(record)

                            }}></img>



                    }
                </div>}


            </>,
        },
    ]

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record) => (

                {
                    record,
                    inputType: 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record),
                }),
        }
    })
    const edit = (record) => {
        form.setFieldsValue({

            ...record,
        })
        setEditingKey(record.ID)
    }
    const save = async (ID) => {
        let token = sessionStorage.getItem("ZeitroA")
        try {

            const row = await form.validateFields()


            for (let key in row) {
                if (row.hasOwnProperty(key)) {
                    // 修改对象的每个属性值
                    row[key] = row[key] == undefined ? '' : row[key]
                }
            }
            let roles = row.Roles
            if (typeof roles === 'string') {
                roles = [roles]
            }
            // if ((row.LastName == 'Required' || row.FirstName == 'Required' || row.Email == 'Required')) return
            setIsVerify({
                Email: row.Email == '' ? false : true,
                FirstName: row.FirstName == '' ? false : true,
                LastName: row.LastName == '' ? false : true,
                Nmls: row.Nmls == '' ? false : true,
                Roles: row.Roles.length == 0 ? false : true
            })
            // form.setFieldsValue({
            //     Email: row.Email == '' ? 'Required' : row.Email,
            //     FirstName: row.FirstName == '' ? 'Required' : row.FirstName,
            //     LastName: row.LastName == '' ? 'Required' : row.LastName,
            //     Nmls: row.Nmls == '' && (roleSelect == 'PriceEngineUser' || roleSelect == 'LoanOfficer' || roleSelect == 'Owner') ? 'Required' : row.Nmls,
            //     Roles: row.Roles.length == 0 ? undefined : row.Roles,

            //     ID: ''

            // })
            if ((roleSelect == 'PriceEngineUser' || roleSelect == 'LoanOfficer' || roleSelect == 'Owner') && row.Nmls == '') return
            if (row.Email == '' || row.FirstName == '' || row.LastName == '' || row.Roles.length == 0) {

                return
            }

            let tosend = {
                email: row.Email.toLowerCase(),
                firstname: row.FirstName,
                lastname: row.LastName,


                nmls: row.Nmls,
                roles: roles,
            }
            const newData = [...dataSource]
            const index = newData.findIndex((item) => ID === item.ID)
            if (index > -1) {
                let url = ID == '' ? '/los/createloaccount' : '/los/updatelo'
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {

                        Cache: "no-cache",
                        'Content-Type': 'application/json;charset=utf-8',
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify({ ...tosend, id: ID })
                })
                const { Text, Status } = await response.json()
                if (Status === 'Error') {
                    if (Text.includes('NMLS')) {
                        message.error('Each NMLS number is allowed to be associated with only one Zeitro account. Please verify the accuracy of NMLS numbers provided.')
                        setIsSameNMLS(true)
                    }
                    if (Text.includes('Email already exists')) {
                        setIsSameEmail(true)
                        message.error('The email address is already in use. Please use a different one.')

                    }




                } else {
                    setIsSameNMLS(false)
                    setIsSameEmail(false)
                    setEditingKey('')

                    form.setFieldsValue({
                        Email: '',
                        FirstName: '',
                        LastName: '',
                        Nmls: '',
                        Roles: undefined,
                        ID: ''
                    })
                    getList()
                    setShowGrayAdd(false)


                }
            }


        } catch (error) {
            console.log('Validate Failed:', error)
        }

    }
    useEffect(async () => {
        getList()
    }, [])
    return (
        <div >
            <div className='ModalTitle'>
                Add team members
            </div>
            <div style={{ height: 480, overflowY: 'scroll' }}>

                <div style={{ marginTop: 15, fontSize: 16, fontWeight: 400, fontFamily: "Inter" }}>

                    Add your team members in this step. After clicking{<img src='/images/ok.svg' style={{ padding: '0 10px' }}></img>} <span style={{ fontWeight: 600 }}>an invitation email will be sent to them.</span> You can also add later in <span style={{ fontWeight: 600 }}> Management {'>'} User management.</span>
                </div>
                <div className='mt-2' >
                    <Form form={form} component={false} onValuesChange={(e) => {


                    }}>
                        <Table loading={loading}

                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            pagination={false}
                            className="allLoansTable custom-table"
                            columns={mergedColumns}
                            dataSource={dataSource}
                            showSorterTooltip={false}
                            scroll={{
                                x: true,

                            }}>

                        </Table>
                    </Form>
                </div>

            </div>




        </div>

    )
    async function getList() {
        try {
            let token = sessionStorage.getItem("ZeitroA")
            setLoading(true)
            const response = await fetch('/los/loanofficerlist', {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    Cache: "no-cache"
                }
            })
            const { LoanOfficers } = await response.json()
            let docs = LoanOfficers.map(s => {
                return {
                    ...s,
                    FullName: s.FirstName + " " + s.LastName
                }
            }).sort((a, b) => {

                const aValue = a.Status === 'disabled'
                const bValue = b.Status === 'disabled'

                if (aValue === bValue) return 0 // 如果两者value属性都是（或都不是）'disable'，保持原有顺序

                // 将value为'disable'的元素排在后面
                return aValue ? 1 : -1

            })
            setDataSource([...docs, {
                AccountTier: "Free",
                Email:
                    "",
                FirstName: "",
                ID: "",
                LastName: "",
                Nmls: "",
                Roles: [],
                Status: "invited",

            }])
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }


    }
}
