import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { Modal, Pagination, Button, Image, Dropdown, Row, Col, Select, Skeleton } from 'antd';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import Interview1003View from "../Interview1003View/Interview1003View";
import { cloneDeep } from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf';
import { updateApplicationByPath } from '../Store/actions'
import './VerifyModal.css';

const mapStateToProps = (state) => {
    return {
        application: state.application,
        progress: state.progress,
        borrower: state.borrower,
    }
}
const mapDispatchToProps = (dispatch) => ({
    updateApplicationByPath: (path, value) => {
        dispatch(updateApplicationByPath(path, value))
    }
})
export default function VerifyModal(props) {
    pdfjs.GlobalWorkerOptions.workerSrc =
        `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const {
        mode, data, open, file, files, pageNumber, numPages, scale, loanid, onCancel, extractedData, mappingData, complete, change, loadSuccess, handlePage, handleZoom, manualVerify, handleDocument
    } = props;
    const [hoveredKey, setHoveredKey] = useState(null)
    const [updatedKeys, setUpdatedKeys] = useState({})
    const [data1, setData1] = useState([])
    const [data2, setData2] = useState([])
    const [data3, setData3] = useState([])
    const [currentData, setCurrentData] = useState([])
    const [initialData, setInitialData] = useState([])
    const [currentData2, setCurrentData2] = useState([])
    const [initialData2, setInitialData2] = useState([])
    const [isUpdateAll, setIsUpdateAll] = useState(false)
    const [pageWidth, setPageWidth] = useState(0)
    const [changeSaved, setChangeSaved] = useState(false)
    const [viewType, setViewType] = useState("")
    const [borrowerOptions, setBorrowerOptions] = useState([])
    const [verifyConfirmStatus, setVerifyConfirmStatus] = useState(false)
    const containerRef = useRef(null)
    const manualChangeTimeout = useRef(null)
    const [currentPage, setCurrentPage] = useState(1)
    const isManualChange = useRef(false)
    const isAtBoundary = useRef(false)
    const isLeftSyncing = useRef(false)
    const justSwitched = useRef(false)
    const [previewBarVisible, setPreviewBarVisible] = useState(false)
    const [hideTimeout, setHideTimeout] = useState(null)
    useEffect(() => {
        const noBorrowerApp = props.application.coborrower.firstname && data?.taskName?.includes(props.application.coborrower.firstname)
        const nocoborrowerApp = data?.taskName?.includes(props.application.borrower.firstname)
        if (open && mode === 'smart') {
            if (files && files.length > 0) {
                const extractedResults = []
                const mappingResults = []
                const mappingResults2 = []
                files.forEach(file => {
                    const extractedResult = mapData(file.Mapping1003, file.ExtractedData, file.ExtractedData[0].extractedData, 'DocPath')
                    if (!noBorrowerApp) {   
                        const mappingResult = mapData(file.Mapping1003, file.ExtractedData, props.application, 'StatePath')
                        mappingResults.push(mappingResult)
                    }
                    if (!nocoborrowerApp && props.application.hascoborrower === "withcoborrower") {
                        const mappingResult2 = mapData(processMappingData(file.Mapping1003), file.ExtractedData, props.application, 'StatePath')
                        mappingResults2.push(mappingResult2)
                    }
                    extractedResults.push(extractedResult)
                })
                
                setData1(extractedResults)
                if (!noBorrowerApp && mappingResults.length > 0) {
                    setData2(mappingResults)
                    setCurrentData(mappingResults)
                    setInitialData(mappingResults)
                }
                if (!nocoborrowerApp && mappingResults2.length > 0) {
                    setData3(mappingResults2)
                    setCurrentData2(mappingResults2)
                    setInitialData2(mappingResults2)
                }
                setHoveredKey({})
                setUpdatedKeys({})
                setIsUpdateAll(false)
            }
        } else if (open && mode === 'manual') {
            const options = []
            if (!noBorrowerApp) {   
                options.push({
                    value: `borrower`,
                    label: `${props.application.borrower.firstname} ${props.application.borrower.lastname}`
                })
            }
            if (!nocoborrowerApp && props.application.hascoborrower === "withcoborrower") {
                options.push({
                    value: `coborrower`,
                    label: ` ${props.application.coborrower.firstname} ${props.application.coborrower.lastname}`
                })
            }
            setBorrowerOptions(options)
            setViewType(noBorrowerApp ? 'coborrower' : 'borrower')
        }
    }, [open, files])
    useEffect(() => {
        return () => {
            if (hideTimeout) {
                clearTimeout(hideTimeout)
            }
        };
    }, [hideTimeout])
    useEffect(() => {
        if (open) {
            setCurrentPage(1)
        }
    }, [open])
    useEffect(() => {
        return () => {
            if (manualChangeTimeout.current) {
                clearTimeout(manualChangeTimeout.current)
            }
        }
    }, [])
    useLayoutEffect (() => {
        if (open && mode === 'manual') {
            const handleResize = () => {
                if (containerRef.current) {
                    setPageWidth(containerRef.current.offsetWidth - 24)
                }
            }
            setTimeout(handleResize, 0)
            window.addEventListener('resize', handleResize)
    
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }
    }, [open, mode])

    const resetScrollHandling = () => {
        containerRef.current.removeEventListener('scroll', resetScrollHandling)
        containerRef.current.addEventListener('scroll', handleScroll)
    }

    const handleScroll = () => {
        if (isManualChange.current || isLeftSyncing.current) return
        const scrollTop = containerRef.current.scrollTop
        const containerHeight = containerRef.current.offsetHeight
        const scrollHeight = containerRef.current.scrollHeight
    
        if (handleScroll.lastScrollTop === undefined) {
            handleScroll.lastScrollTop = 0
        }
        const isScrollingDown = scrollTop > handleScroll.lastScrollTop
        handleScroll.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop
    
        if (justSwitched.current) {
            return
        }
        
        if (isAtBoundary.current) {
            isAtBoundary.current = false
            return
        }
        if (isScrollingDown && scrollTop + containerHeight >= scrollHeight) {
            changeFile('next')
    
            containerRef.current.removeEventListener('scroll', handleScroll)
    
            requestAnimationFrame(() => {
                containerRef.current.addEventListener('scroll', resetScrollHandling)
            })
        } else if (!isScrollingDown && scrollTop <= 0) {
            changeFile('prev')
    
            containerRef.current.removeEventListener('scroll', handleScroll)
    
            requestAnimationFrame(() => {
                containerRef.current.addEventListener('scroll', resetScrollHandling)
            })
        }
        const anchors = document.querySelectorAll('.pdf-verify-anchor')
        let closestPage = currentPage
        let minDistance = Infinity
    
        anchors.forEach(anchor => {
            const { top } = anchor.getBoundingClientRect()
            const distance = Math.abs(top - containerHeight / 2)
            if (distance < minDistance) {
                minDistance = distance
                closestPage = parseInt(anchor.id.split('-')[1])
            }
        })
    
        if (closestPage !== currentPage) {
            setCurrentPage(closestPage)
        }
    
        setTimeout(() => {
            isLeftSyncing.current = false
        }, 100)
    }

    const changeFile = (type) => {
        justSwitched.current = true
        let newPage
        const toNum = type === 'prev' ? data?.num - 1 : data?.num + 1
        if (type === 'prev' && data && data?.num !== 1) {
            change(toNum)
            newPage = 1
        } else if (type === 'next' && data && data?.num !== data?.total) {
            change(toNum)
            newPage = 1
        } else {
            newPage = currentPage
        }
        setCurrentPage(newPage)
        setTimeout(() => {
            justSwitched.current = false
        }, 500)
    }
    const verifyCancel = () => {
        onCancel()
    }

    const processMappingData = (mappingData) => {
        const newMappingData = cloneDeep(mappingData)
      
        const processField = (field) => {
          if (field.StatePath) {
            field.StatePath = field.StatePath.replace(/\bborrower\b/g, 'coborrower');
          }
      
          if (field.StateChanges) {
            field.StateChanges = field.StateChanges.map(change => ({
              ...change,
              path: change.path.replace(/\bborrower\b/g, 'coborrower')
            }))
          }
        }
      
        const recursiveProcess = (obj) => {
          Object.keys(obj).forEach(key => {
            const field = obj[key]
            if (typeof field === 'object' && field !== null) {
              if (field.StatePath || field.StateChanges) {
                processField(field)
              } else {
                recursiveProcess(field)
              }
            }
          })
        }
      
        recursiveProcess(newMappingData)
      
        return newMappingData;
      };
    const getValueByPath = (obj, path, defaultValue = undefined) => {
        const parts = path.split('.')
        let result = obj
        for (let part of parts) {
            if (result && typeof result === 'object' && part in result) {
                result = result[part]
            } else {
                return defaultValue
            }
        }
        if (result && typeof result === 'object' && !Array.isArray(result)) {
            return defaultValue
        }
        return result
    }
    const mapData = (mapping, extractedData, source, sourceType) => {
        const result = {}
        Object.keys(mapping).forEach(key => {
            if (typeof mapping[key] === 'object' && mapping[key] !== null) {
                if (mapping[key][sourceType]) {
                    let value = getValueByPath(source, mapping[key][sourceType])
                    if (value !== '' && mapping[key].ValueType && mapping[key].ValueType === 'intstring') {
                        value = parseInt(value)
                    }
                    result[key] = value
                } else {
                    result[key] = mapData(mapping[key], extractedData, source, sourceType)
                }
            }
        })
        return result
    }
    const countDiscrepancies = (dataA, dataB, dataC) => {
        let count = 0
        const isEqual = (val1, val2) => (val1 === val2) || (val1 === "" && val2 === undefined) || (val1 === undefined && val2 === "")
        const recursiveCount = (objA, objB, objC) => {
            Object.keys(objA).forEach(key => {
                const valueA = objA[key]
                const valueB = objB ? objB[key] : undefined
                const valueC = objC ? objC[key] : undefined
    
                if (typeof valueA === 'object' && valueA !== null) {
                    recursiveCount(valueA, valueB, valueC)
                } else {
                    const diffWithB = objB && !isEqual(valueA, valueB)
                    const diffWithC = objC && !isEqual(valueA, valueC)
    
                    if (diffWithB || diffWithC) {
                        count++
                    }
                }
            })
        }
    
        if (dataB.length === 0 && dataC.length === 0) {
            return count
        } else if (dataB.length === 0) {
            dataA.forEach((itemA, index) => {
                const itemC = dataC[index] || {}
                recursiveCount(itemA, null, itemC)
            })
        } else if (dataC.length === 0) {
            dataA.forEach((itemA, index) => {
                const itemB = dataB[index] || {}
                recursiveCount(itemA, itemB, null)
            })
        } else {
            dataA.forEach((itemA, index) => {
                const itemB = dataB[index] || {}
                const itemC = dataC[index] || {}
                recursiveCount(itemA, itemB, itemC)
            })
        }
        return count
    }
    const getNestedValue = (obj, path) => {
        return path.reduce((acc, key) => acc && acc[key], obj)
    }

    const setNestedValue = (obj, path, value) => {
        let target = obj
        path.forEach((key, idx) => {
            if (idx === path.length - 1) {
                target[key] = value
            } else {
                target = target[key]
            }
        })
    }
    const updateAll = () => {
        const updatedKeys = {}
        const updatedData2 = cloneDeep(data1)
        const recursiveUpdate = (obj1, obj2, path = []) => {
            Object.keys(obj1).forEach(key => {
                const currentPath = [...path, key]
                const pathString = currentPath.join('.')
                if (typeof obj1[key] === 'object' && obj1[key] !== null) {
                    recursiveUpdate(obj1[key], obj2[key], currentPath)
                } else if (obj1[key] !== obj2[key]) {
                    updatedKeys[pathString] = true
                }
            })
        }
        setIsUpdateAll(true)
        recursiveUpdate(data1, data2)
        setUpdatedKeys(updatedKeys)
        setCurrentData(updatedData2)
    }

    const undoAll = () => {
        setIsUpdateAll(false)
        setUpdatedKeys({})
        setCurrentData(cloneDeep(initialData))
    }
    const handleVerify = () => {
        complete(currentData, currentData2, updatedKeys)
    }
    const manualComplete = () => {
        if (borrowerOptions.length > 1 && viewType === 'borrower') {
            setVerifyConfirmStatus(true)
        } else {
            manualVerify()
        }
    }
    const completeVerify = () => {
        setVerifyConfirmStatus(false)
        manualVerify()
    }
    const saveProgress = () => {
        setChangeSaved(true)
        setTimeout(() => setChangeSaved(false), 3000)
    }
    const arrayBufferToBlob = (buffer, type) => {
        return new Blob([buffer], { type })
    }
    const createImageURLFromBase64 = (arrayBuffer, type) => {
        const blob = arrayBufferToBlob(arrayBuffer, type)
        return URL.createObjectURL(blob)
    }
    const onDocumentLoadSuccess = (page) => {
        loadSuccess(page)
    }
    const onChangePage = (page) => {

        isManualChange.current = true

        if (page === 1 || page === numPages) {
            isAtBoundary.current = true
        } else {
            isAtBoundary.current = false
        }
    
        setCurrentPage(page)
        const pdfTargetElement = document.querySelector(`#pdfVerifyPage-${page}`)
    
        if (pdfTargetElement) {
            containerRef.current.scrollTo({
                top: pdfTargetElement.offsetTop,
                behavior: 'smooth',
            })
        }
    
        if (manualChangeTimeout.current) {
            clearTimeout(manualChangeTimeout.current)
        }

        manualChangeTimeout.current = setTimeout(() => {
            isManualChange.current = false
        }, 500)
    }
    const changeZoom = (type) => {
        if (type === 'add') {
            handleZoom(+(Math.min(scale + 0.1, 3).toFixed(1)));
        } else {
            handleZoom(+(Math.max(scale - 0.1, 0.5).toFixed(1)));
        }
    }
    const handleDoc = (e) => {
        handleDocument(e.key)
    }
    const isEqual = (val1, val2) => {
        return (val1 === val2) || (val1 === undefined && val2 === "") || (val1 === "" && val2 === undefined);
    };
    const RecursiveTable = ({
        documentData,
        applicationData,
        secondaryApplicationData,
        level = 0,
        path = [],
        hoveredKey,
        setHoveredKey,
        fileName,
        fileIndex
    }) => {
        if (!documentData || (!applicationData && !secondaryApplicationData)) {
            return null;
        }
    
        return (
            <div>
                {Object.keys(documentData).map(key => {
                    const isTitle = typeof documentData[key] === 'object' && documentData[key] !== null;
                    const canUpdatePrimary = applicationData ? !isEqual(documentData[key], applicationData[key]) : false;
                    const canUpdatedSecondary = secondaryApplicationData ? !isEqual(documentData[key], secondaryApplicationData[key]) : false;
                    const hasUpdatedPrimary = applicationData ? !isEqual(initialData[key], applicationData[key]) : false
                    const hasUpdatedSecondary = secondaryApplicationData ? !isEqual(initialData2[key], secondaryApplicationData[key]) : false;
                    const currentPath = [...path, key];
                    const pathString = `${fileIndex}.${currentPath.join('.')}`;
                    const handleMouseEnter = () => setHoveredKey(pathString);
                    const handleMouseLeave = () => setHoveredKey(null);
    
                    const handleUpdateClick = () => {
                        setHoveredKey(null);
                        setUpdatedKeys(prev => ({ ...prev, [pathString]: true }));
                        if (canUpdatePrimary) {
                            setCurrentData(prev => {
                                const updatedData2 = cloneDeep(prev);
                                setNestedValue(updatedData2[fileIndex], currentPath, documentData[key]);
                                return updatedData2;
                            })
                        }
                        if (secondaryApplicationData && canUpdatedSecondary) {
                            setCurrentData2(prev => {
                                const updatedData2 = cloneDeep(prev);
                                setNestedValue(updatedData2[fileIndex], currentPath, documentData[key]);
                                return updatedData2;
                            })
                        }
                    };
    
                    const handleUndoClick = () => {
                        setUpdatedKeys(prev => ({ ...prev, [pathString]: false }))
                        if (hasUpdatedPrimary) {
                            setCurrentData(prev => {
                                const updatedData2 = cloneDeep(prev)
                                setNestedValue(updatedData2[fileIndex], currentPath, getNestedValue(initialData[fileIndex], currentPath))
                                return updatedData2
                            })
                        }
                        if (secondaryApplicationData && hasUpdatedSecondary) { 
                            setCurrentData2(prev => {
                                const updatedData2 = cloneDeep(prev)
                                setNestedValue(updatedData2[fileIndex], currentPath, getNestedValue(initialData2[fileIndex], currentPath))
                                return updatedData2
                            })
                        }
                    }
    
                    const displayValuePrimary = applicationData 
                    ? (hoveredKey === pathString ? documentData[key] : applicationData[key])
                        : null
                    const displayValueSecondary = secondaryApplicationData
                        ? (hoveredKey === pathString ? documentData[key] : secondaryApplicationData[key])
                        : null
    
                    const displayStylePrimary = applicationData ? (hoveredKey === pathString && applicationData[key] !== documentData[key])
                        ? { fontStyle: 'italic', color: '#325CEB' }
                        : { fontStyle: 'italic' } : {}
    
                    const displayStyleSecondary = secondaryApplicationData ? (hoveredKey === pathString && secondaryApplicationData[key] !== documentData[key])
                        ? { fontStyle: 'italic', color: '#325CEB' }
                        : { fontStyle: 'italic' } : {}
    
                    
                    return (
                        <div key={key}>
                            <div 
                                className={`field-row ${isTitle ? 'title-row' : ''} 
                                ${(canUpdatePrimary || canUpdatedSecondary) && !isTitle ? 'update-row' : ''}`}
                                style={ updatedKeys[pathString] ? { alignItems: 'flex-start' } : {} }
                            >
                                <div className="field-key" style={{ textIndent: `${level * 38}px` }}>
                                    {key}
                                </div>
    
                                {!isTitle && (
                                    <>
                                        <div className="field-value">{documentData[key]}</div>
    
                                        <div className="field-value" style={applicationData && secondaryApplicationData ? { display: 'flex', flexDirection: 'column', flexGrow: 2 } : {display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                                            <div style={{display: 'flex'}}>
                                                {
                                                    applicationData && (
                                                        <div className='application-row'>
                                                            <div style={displayStylePrimary}>{displayValuePrimary}</div>
                                                            {hasUpdatedPrimary && !hasUpdatedSecondary && updatedKeys[pathString] && (
                                                                <div 
                                                                    className="undo" 
                                                                >
                                                                    Updated w/ doc data
                                                                    <span onClick={handleUndoClick}>Undo</span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                                {secondaryApplicationData && (
                                                    <div className='application-row' style={{textIndent: 24}}>
                                                        <div style={displayStyleSecondary}>{displayValueSecondary}</div>
                                                        {!hasUpdatedPrimary && hasUpdatedSecondary && updatedKeys[pathString] && (
                                                        <div 
                                                            className="undo" 
                                                        >
                                                            Updated w/ doc data
                                                            <span onClick={handleUndoClick}>Undo</span>
                                                        </div>
                                                    )}
                                                    </div>
                                                )}
                                            </div>
                                            {hasUpdatedPrimary && hasUpdatedSecondary && updatedKeys[pathString] && (
                                                <div 
                                                    className="undo" 
                                                >
                                                    Updated with document data
                                                    <span onClick={handleUndoClick}>Undo</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className='field-action'>
                                            {(canUpdatePrimary || canUpdatedSecondary) && (
                                                <div 
                                                    onMouseEnter={handleMouseEnter} 
                                                    onMouseLeave={handleMouseLeave} 
                                                    onClick={handleUpdateClick} 
                                                    className='update-btn'
                                                >
                                                    Update
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            {isTitle && (
                                <RecursiveTable
                                    documentData={documentData[key]}
                                    applicationData={applicationData ? applicationData[key] : null}
                                    secondaryApplicationData={secondaryApplicationData ? secondaryApplicationData[key] : null}
                                    level={level + 1}
                                    path={currentPath}
                                    hoveredKey={hoveredKey}
                                    setHoveredKey={setHoveredKey}
                                    fileName={fileName}
                                    fileIndex={fileIndex}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };
    function getFileExtension(filename) {
        return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2)
    }
    const removeFileExtension = (fileName) => {
        return fileName.replace(/\.[^/.]+$/, "")
    }
    const footer = [
        <Button className="verify-cancel-btn" onClick={verifyCancel}>Back</Button>,
        mode === 'smart' ? (
            countDiscrepancies(data1, currentData, currentData2) < countDiscrepancies(data1, initialData, initialData2) ? <Button className="verify-complete-btn" type="primary" onClick={handleVerify}>Complete verification</Button> : <Button className="verify-skip-btn" type="link" onClick={handleVerify}>Dismiss suggestions and complete verification</Button>
        ) : (
            <div style={{display: 'flex', alignItems: 'center'}}>{changeSaved ? <div className='change-saved'>Changes saved to 1003<img src='/images/document/change-saved.svg' /></div> : <Button style={{marginRight: 30}} className="verify-skip-btn" type="link" onClick={saveProgress}>Save progress</Button>}<Button className="verify-complete-btn" type="primary" onClick={manualComplete}>Complete verification</Button></div>
        )
    ]
    const getName = (type) => {
        return `${props.application[type].firstname}’s 1003 Application`
    }
    const renderFileData = (file, index) => {
        return (
            <div key={file.name}>
                <div className='update-table-header'>
                    <div className='header-col'><div className='file-name'><img src={ getFileExtension(file.name) === "pdf" ? '/images/pdf-icon.svg' : '/images/img-icon.svg'}/><div>{removeFileExtension(file.name)}</div></div></div>
                    <div className='header-col doc'>Document data</div>
                    <div className='header-col application-col' style={currentData.length === 0 || currentData2.length === 0 ? {flexGrow: 1} : {flexGrow: 2}}>
                        {currentData.length > 0 && <div className='header-col application'>{getName('borrower')}</div>}
                        {currentData2.length > 0 && <div style={{textIndent: 24}} className='header-col application'>{getName('coborrower')}</div>}
                    </div>
                    <div style={{ width: 95 }}></div>
                </div>
                <div className='update-table'>
                <RecursiveTable
                    documentData={data1[index]}
                    applicationData={currentData.length > 0 ? currentData[index] : null}
                    secondaryApplicationData={currentData2.length > 0 ? currentData2[index] : null}
                    hoveredKey={hoveredKey}
                    setHoveredKey={setHoveredKey}
                    fileName={file.name}
                    fileIndex={index}
                />
                </div>
            </div>
        );
    }
    const handleChangeBorrower = (value) => {
        setViewType(value)
    }
    
    const verifyConfirmCancel = () => {
        setVerifyConfirmStatus(false)
    }
    const verifyCoborrower = () => {
        setVerifyConfirmStatus(false)
        setViewType('coborrower')
    }
    const handleMouseEnter = () => {
        setPreviewBarVisible(true)
        if (hideTimeout) {
            clearTimeout(hideTimeout)
            setHideTimeout(null)
        }
    }

    const handleMouseLeave = () => {
        const timeout = setTimeout(() => {
            setPreviewBarVisible(false)
        }, 3000)
        setHideTimeout(timeout)
    }
    return (
        <>
            <Modal
                className='document-verify-modal'
                maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.85)' }}
                destroyOnClose
                maskClosable={true}
                width='90%'
                open={open}
                title=""
                onCancel={verifyCancel}
                footer={footer}
            >
                <div className='verify-modal-top'><div>Document verification | {data?.taskName}</div>{mode === 'manual' && <Button className="verify-skip-btn" type="link" onClick={() => handleDoc({key: 'resubmission'})}>Request document resubmission</Button>}</div>
                {
                    mode === 'smart' ? (
                        <div className='verify-modal-content'>
                            <div className='verify-title'>Our AI checked for discrepancies between the document data and the 1003 application form.</div>
                            <div style={countDiscrepancies(data1, currentData, currentData2) === 0 ? {backgroundColor: '#E1F2E2'} : {}} className='update-action-bar'>
                                <div className='action-bar-l'>
                                    <img src={`/images/document/verify-${countDiscrepancies(data1, currentData, currentData2) === 0 ? 'passed' : 'difference'}.svg`} />
                                    {countDiscrepancies(data1, currentData, currentData2) === 0 ? <div style={{color: '#2E605A'}}>All information is correct</div> : <div>{countDiscrepancies(data1, currentData, currentData2)} discrepancies were detected. Update the 1003 application form’s data?</div>}
                                </div>
                                {
                                    countDiscrepancies(data1, currentData, currentData2) === 0 ? (
                                        isUpdateAll ? <div className='undo-all'><span>All updates applied</span><div className='undo' onClick={undoAll}>Undo</div></div> : null 
                                    ) : (
                                        <div className='update-all' onClick={updateAll}>Update all</div>
                                    )
                                }
                                
                            </div>
                            <div className='verify-table'>
                                {files && files.length > 0 && (
                                    files.map((file, index) => renderFileData(file, index))
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className='manual-modal-content'>
                            <Row>
                                <Col style={{position: 'relative'}} span={12}>
                                    <div className='document-preview-content' ref={containerRef} onScroll={handleScroll}>
                                        <div style={{ width: pageWidth * scale }} className='preview-box'>
                                            {
                                                data?.MimeType === "application/pdf" ? (
                                                    <Document error={<Skeleton active />} loading={<Skeleton active />} file={file} onLoadSuccess={onDocumentLoadSuccess}>
                                                        {Array.from(new Array(numPages), (el, index) => (
                                                            <div id={`pdfVerifyPage-${index + 1}`} key={`page_${index + 1}`} style={{ width: pageWidth * scale }} className="pdf-verify-anchor">
                                                                <Page loading="" renderAnnotationLayer={false} pageNumber={index + 1} width={ pageWidth * scale } />
                                                            </div>
                                                        ))}
                                                    </Document>
                                                ) : (
                                                    <Image
                                                        preview={false}
                                                        width={pageWidth * scale}
                                                        src={createImageURLFromBase64(file, data?.MimeType )}
                                                    />
                                                )

                                            }
                                        </div>
                                    </div>
                                    <div 
                                        className='document-preview-top-bar'
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <div className={`change-file ${previewBarVisible ? 'visible' : ''}`}>
                                            <img className={`prev-file ${data?.num === 1 && 'arrow-disabled'}`} src={`/images/document-arrow-left-disabled.svg`} onClick={() => changeFile('prev')}/>
                                            <div className='flie-total'>File <span>{data?.num} / {data?.total}</span></div>
                                            <div className='separate'></div>
                                            <div className='file-name'><img src={ data?.MimeType === "application/pdf" ? '/images/pdf-icon.svg' : '/images/img-icon.svg'}/><div>{data?.FileName.replace(/\.[^/.]+$/, "")}</div></div>
                                            <img className={`next-file ${data?.num === data?.total && 'arrow-disabled'}`} src={`/images/document-arrow-right-disabled.svg`} onClick={() => changeFile('next')}/>
                                        </div>
                                    </div>
                                    <div className='document-preview-bottom-bar'>
                                        <div className="document-preview-pagination">
                                            <Pagination size='small' simple current={data?.MimeType === "application/pdf" ? currentPage : 1} pageSize={1} total={data?.MimeType === "application/pdf" ? numPages : 1} onChange={onChangePage} />
                                        </div>
                                        <div className="document-preview-scale">
                                            <div className='zoom-min'><MinusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('min')} /></div>
                                            <div className='zoom-value'>{Math.round(scale * 100)}%</div>
                                            <div className='zoom-add'><PlusOutlined style={{ fontSize: 16 }} onClick={() => changeZoom('add')} /></div>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className='form-1003-title'>
                                        <div>1003 Application Form</div>
                                        { borrowerOptions.length > 1 &&
                                            <Select
                                                style={{height: 36}}
                                                value={viewType}
                                                onChange={handleChangeBorrower}
                                                options={borrowerOptions}
                                            />
                                        }
                                    </div>
                                    <Interview1003View 
                                        // key={loanid} 
                                        // borrowerid={this.props.borrower.id}
                                        view={viewType}
                                        mode='document'
                                        handingOff={false} 
                                        handingOffType={false}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )
                }
            </Modal>
            <Modal
                className='verify-confirm-modal'
                centered 
                width={500}
                open={verifyConfirmStatus}
                onCancel={verifyConfirmCancel}
                footer={null}
            >   
                <div className='verify-confirm-title'>Confirm Verification Completion</div>
                <div className='verify-confirm-text'>It seems you have completed verifying {borrowerOptions.find(item => item.value === 'borrower')?.label}’s 1003 Application Form. Would you like to verify {borrowerOptions.find(item => item.value === 'coborrower')?.label}'s 1003 Application Form as well?</div>
                <div className='verify-confirm-action'>
                    <div className='confirm-btn theme-btn' onClick={verifyCoborrower}>View {props.application.coborrower.firstname}’s 1003 Application Form</div>
                    <div className='confirm-btn default-btn' onClick={completeVerify}>Complete Verification Anyway</div>
                </div>
            </Modal>
        </>
    )
}
VerifyModal = connect(mapStateToProps, mapDispatchToProps)(VerifyModal)