import React from 'react'
import { Modal } from 'antd'
import "./FullHeightModal.css"


const FullScreenModal = ({ open, onClose, title, content, footer, bodyStyle, wrapClassName, width }) => {
    bodyStyle = bodyStyle || { borderTop: '1px solid #DBDCDE', width: '100%', position: 'absolute', zIndex: '9999', top: 75, right: 0 }
    if (title === '' && !bodyStyle) {
        bodyStyle = { width: '100%', position: 'absolute', zIndex: '9999', top: 75, right: 0 }
    }
    return (
        <Modal
            open={open}
            onCancel={onClose}
            footer={footer}
            width={width == undefined ? '80%' : width}
            style={{ top: 0 }}
            bodyStyle={{ display: 'flex', flexDirection: 'column' }}
            title={title}
            wrapClassName={wrapClassName || 'full-height-modal'}
            classNames={{
                content: 'Full_modal_content',
                body: 'Full_modal_body'
            }}
        >

            {title && <div style={bodyStyle}></div>}

            {content}

        </Modal>
    )
}

export default FullScreenModal
