import {
    DocPromptExamples,
    FHADocPromptExamples,
    VADocPromptExamples,
    NewWaveLendingDIPrimeDocPromptExamples,
    NewWaveLendingDIDocPromptExamples,
    NewWaveLendingPLDocPromptExamples,
    NewWaveLendingVOEDocPromptExamples,
    NewWaveLendingEPDocPromptExamples,
    NewFiNQMEPDocPromptExamples,
    NewFiNQMIEPDocPromptExamples,
    NewFiDSCRDocPromptExamples,
    NewFiFNDSCRDocPromptExamples,
    NewFiNQMPDocPromptExamples,
    NewFiNQMIPDocPromptExamples,
    NewFiPINNDocPromptExamples,
    NewFiRAINERDocPromptExamples,
} from './utils'

export const PDF_GUIDELINES = {
    FANNIE_MAE: '/fannie_mae_guideline.pdf',
    VA: '/VA_guideline.pdf',
    FHA: '/FHA.pdf',
    NEWFI_JUMBO: '/newfi_jumbo_guideline.pdf',
    NEW_WAVE_LENDING_DI_PRIME: '/nonqm/new_wave_lending/DI_PRIME.pdf',
    NEW_WAVE_LENDING_DI: '/nonqm/new_wave_lending/DI.pdf',
    NEW_WAVE_LENDING_PL: '/nonqm/new_wave_lending/PL.pdf',
    NEW_WAVE_LENDING_VOE: '/nonqm/new_wave_lending/VOE.pdf',
    NEW_WAVE_LENDING_EP: '/nonqm/new_wave_lending/EP.pdf',
    NEWFI_NQMEP: '/nonqm/newfi/NQMEP.pdf',
    NEWFI_NQMIEP: '/nonqm/newfi/NQMIEP.pdf',
    NEWFI_DSCR: '/nonqm/newfi/DSCR.pdf',
    NEWFI_FNDSCR: '/nonqm/newfi/FNDSCR.pdf',
    NEWFI_NQMP: '/nonqm/newfi/NQMP.pdf',
    NEWFI_NQMIP:'/nonqm/newfi/NQMIP.pdf',
    NEWFI_PINN: '/nonqm/newfi/PINN.pdf',
    NEWFI_RAINER: '/nonqm/newfi/RAINER.pdf',
}

export const CONTENT_TYPES = {
    FANNIE_MAE: 'fanieMaeDocName',
    FHA: 'FHA',
    VA: 'va',
    NEW_WAVE_LENDING_DI_PRIME: 'newWaveLendingDIPrime',
    NEW_WAVE_LENDING_DI: 'newWaveLendingDI',
    NEW_WAVE_LENDING_PL: 'newWaveLendingPL',
    NEW_WAVE_LENDING_VOE: 'newWaveLendingVOE',
    NEW_WAVE_LENDING_EP: 'newWaveLendingEP',
    NEWFI_NQMEP: 'NewFiNQMEP',
    NEWFI_NQMIEP: 'NewFiNQMIEP',
    NEWFI_DSCR: 'NewFiDSCR',
    NEWFI_FNDSCR: 'NewFiFNDSCR',
    NEWFI_NQMP: 'NewFiNQMP',
    NEWFI_NQMIP: 'NewFiNQMIP',
    NEWFI_PINN: 'NewFiPINN',
    NEWFI_RAINER: 'NewFiRAINER',
}

export const PDF_TYPE_NAME = {
    [CONTENT_TYPES.FANNIE_MAE]: 'Fannie Mae',
    [CONTENT_TYPES.FHA]: 'FHA',
    [CONTENT_TYPES.VA]: 'VA',
    [CONTENT_TYPES.NEW_WAVE_LENDING_DI_PRIME]: 'New Wave Lending DI Prime',
    [CONTENT_TYPES.NEW_WAVE_LENDING_DI]: 'New Wave Lending DI Investor',
    [CONTENT_TYPES.NEW_WAVE_LENDING_PL]: 'New Wave Lending Prodit and Loss',
    [CONTENT_TYPES.NEW_WAVE_LENDING_VOE]: 'New Wave Lending VOE',
    [CONTENT_TYPES.NEW_WAVE_LENDING_EP]: 'New Wave Lending Expanded Portfolio',
    [CONTENT_TYPES.NEWFI_NQMEP]: 'NewFi NQM Expanded Portfolio',
    [CONTENT_TYPES.NEWFI_NQMIEP]: 'NewFi NQM ITIN Expanded Portfolio',
    [CONTENT_TYPES.NEWFI_DSCR]: 'NewFi DSCR',
    [CONTENT_TYPES.NEWFI_FNDSCR]: 'NewFi FN DSCR',
    [CONTENT_TYPES.NEWFI_NQMP]: 'NewFi NQM',
    [CONTENT_TYPES.NEWFI_NQMIP]: 'NewFi NQM ITIN',
    [CONTENT_TYPES.NEWFI_PINN]: 'NewFi PINN',
    [CONTENT_TYPES.NEWFI_RAINER]: 'NewFi RAINER',
}

export const PDF_CONTENT = {
    [CONTENT_TYPES.VA]: PDF_GUIDELINES.VA,
    [CONTENT_TYPES.FANNIE_MAE]: PDF_GUIDELINES.FANNIE_MAE,
    [CONTENT_TYPES.FHA]: PDF_GUIDELINES.FHA,
    [CONTENT_TYPES.NEW_WAVE_LENDING_DI_PRIME]: PDF_GUIDELINES.NEW_WAVE_LENDING_DI_PRIME,
    [CONTENT_TYPES.NEW_WAVE_LENDING_DI]: PDF_GUIDELINES.NEW_WAVE_LENDING_DI,
    [CONTENT_TYPES.NEW_WAVE_LENDING_PL]: PDF_GUIDELINES.NEW_WAVE_LENDING_PL,
    [CONTENT_TYPES.NEW_WAVE_LENDING_VOE]: PDF_GUIDELINES.NEW_WAVE_LENDING_VOE,
    [CONTENT_TYPES.NEW_WAVE_LENDING_EP]: PDF_GUIDELINES.NEW_WAVE_LENDING_EP,
    [CONTENT_TYPES.NEWFI_NQMEP]: PDF_GUIDELINES.NEWFI_NQMEP,
    [CONTENT_TYPES.NEWFI_NQMIEP]: PDF_GUIDELINES.NEWFI_NQMIEP,
    [CONTENT_TYPES.NEWFI_DSCR]: PDF_GUIDELINES.NEWFI_DSCR,
    [CONTENT_TYPES.NEWFI_FNDSCR]: PDF_GUIDELINES.NEWFI_FNDSCR,
    [CONTENT_TYPES.NEWFI_NQMP]: PDF_GUIDELINES.NEWFI_NQMP,
    [CONTENT_TYPES.NEWFI_NQMIP]: PDF_GUIDELINES.NEWFI_NQMIP,
    [CONTENT_TYPES.NEWFI_PINN]: PDF_GUIDELINES.NEWFI_PINN,
    [CONTENT_TYPES.NEWFI_RAINER]: PDF_GUIDELINES.NEWFI_RAINER,
}

export const PROMPT_EXAMPLES = {
    [CONTENT_TYPES.FANNIE_MAE]: DocPromptExamples,
    [CONTENT_TYPES.FHA]: FHADocPromptExamples,
    [CONTENT_TYPES.VA]: VADocPromptExamples,
    [CONTENT_TYPES.NEW_WAVE_LENDING_DI_PRIME]: NewWaveLendingDIPrimeDocPromptExamples,
    [CONTENT_TYPES.NEW_WAVE_LENDING_DI]: NewWaveLendingDIDocPromptExamples,
    [CONTENT_TYPES.NEW_WAVE_LENDING_PL]: NewWaveLendingPLDocPromptExamples,
    [CONTENT_TYPES.NEW_WAVE_LENDING_VOE]: NewWaveLendingVOEDocPromptExamples,
    [CONTENT_TYPES.NEW_WAVE_LENDING_EP]: NewWaveLendingEPDocPromptExamples,
    [CONTENT_TYPES.NEWFI_NQMEP]: NewFiNQMEPDocPromptExamples,
    [CONTENT_TYPES.NEWFI_NQMIEP]: NewFiNQMIEPDocPromptExamples,
    [CONTENT_TYPES.NEWFI_DSCR]: NewFiDSCRDocPromptExamples,
    [CONTENT_TYPES.NEWFI_FNDSCR]: NewFiFNDSCRDocPromptExamples,
    [CONTENT_TYPES.NEWFI_NQMP]: NewFiNQMPDocPromptExamples,
    [CONTENT_TYPES.NEWFI_NQMIP]: NewFiNQMIPDocPromptExamples,
    [CONTENT_TYPES.NEWFI_PINN]: NewFiPINNDocPromptExamples,
    [CONTENT_TYPES.NEWFI_RAINER]: NewFiRAINERDocPromptExamples,

}

export const PDF_API_URL = {
    [CONTENT_TYPES.FANNIE_MAE]: '/los/mortgagegpt/askfanniemae',
    [CONTENT_TYPES.FHA]: '/los/mortgagegpt/askfha',
    [CONTENT_TYPES.VA]: '/los/mortgagegpt/askva',
    [CONTENT_TYPES.NEW_WAVE_LENDING_DI_PRIME]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEW_WAVE_LENDING_DI]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEW_WAVE_LENDING_PL]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEW_WAVE_LENDING_VOE]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEW_WAVE_LENDING_EP]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEWFI_NQMEP]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEWFI_NQMIEP]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEWFI_DSCR]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEWFI_FNDSCR]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEWFI_NQMP]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEWFI_NQMIP]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEWFI_PINN]: '/los/mortgagegpt/asknonqm',
    [CONTENT_TYPES.NEWFI_RAINER]: '/los/mortgagegpt/asknonqm',
}
