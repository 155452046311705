import React, { Component } from "react"
import * as com from "../Common.js"
import Form from 'react-bootstrap/Form'
import { InputGroup } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css'
import '../App.css'
import { ExclamationCircleOutlined } from '@ant-design/icons'
var parser = require('parse-address')
export default class AddressAutocomplete extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef()
        this.state = {
            value: props.defaultValue
        }

    }

    onValidate = adr => {
        if (!this.props.onCompletion) {
            this.ref.current.setCustomValidity("")
            let parsed = parser.parseLocation(adr)
            if (parsed != null) {
                let busted = false
                if (parsed.number === undefined) {
                    busted = true
                    this.ref.current.setCustomValidity("Need to spedify house number")
                }
                if (parsed.zip === undefined) {
                    busted = true
                    this.ref.current.setCustomValidity("Need to spedify zip code")
                }
                if (!busted)
                    this.ref.current.setCustomValidity("")
            }
        }

    }
    onChange = (e) => {
        e.preventDefault()
        let adr = e.target.value
        this.onValidate(adr)

        this.props.onChange(e)
    }
    onBlur = e => {
        let adr = e.target.value
        this.onValidate(adr)
        if (this.props.onBlur != null) {
            this.props.onBlur(e)
        }
    }
    componentDidMount() {
        if (window.google == null)
            return
        this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById(this.props.id), {
            types: ['address'], componentRestrictions: { country: "us" },
            fields: ["address_components", "formatted_address"]
        })

        this.autocomplete.addListener("place_changed", this.handlePlaceSelect)
        this.onValidate(this.props.defaultValue)
    }
    handlePlaceSelect = () => {
        let addressObject = this.autocomplete.getPlace()
        let address = addressObject.address_components
        if ("undefined" === typeof address)
            return

        let num = com.getSubaddress(address, "street_number", "long_name")
        let str = com.getSubaddress(address, "route", "long_name")

        if (this.props.onCompletion) {
            let straddr = `${num} ${str}`
            this.ref.current.value = straddr
            this.onValidate(address)
            //this.props.onChange({target: {value: straddr}})
            this.props.onCompletion(address)
        } else {
            let city = com.getSubaddress(address, "locality", "long_name")
            let state = com.getSubaddress(address, "administrative_area_level_1", "short_name")
            let zip = com.getSubaddress(address, "postal_code", "long_name")
            let zips = com.getSubaddress(address, "postal_code_suffix", "short_name")
            //let country = com.getSubaddress(address, "country", "long_name")            

            if (zips !== "")
                zip = zip + "-" + zips

            let straddr = `${num} ${str}, ${city}, ${state} ${zip}`
            this.onValidate(straddr)
            this.props.onChange({ target: { value: straddr } })
        }
    }
    render = () => {
        return (
            <Form.Group controlId={this.props.id} className="text-left input-groups" >
                {(this.props.label !== null && this.props.label !== "") ? <Form.Label className="text-left" >{this.props.label}</Form.Label>
                    : ""}
                <Form.Control
                    isValid={false}
                    size={this.props.size}
                    ref={this.ref}
                    required={this.props.required}
                    name={this.props.name}
                    onBlur={this.onBlur}
                    autoComplete={this.props.autocomplete}
                    type={this.props.type}
                    value={this.props.defaultValue}
                    onChange={this.onChange}
                    pattern={this.props.pattern}
                    placeholder={this.props.placeholder}
                    style={this.props.style}
                />

                <Form.Control.Feedback>
                    {this.props.goodfeedback}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                    {this.props.badfeedback}
                </Form.Control.Feedback>
                {this.props.defaultValue === '' && this.props.border && <div className="ExclamationCircleOutlinedAdd"><ExclamationCircleOutlined /></div>}
            </Form.Group>
        )
    }
}